import {Dispatch} from 'redux';
import {ErrorDto} from '../../../models/dtos/error.dto';
import {Errors} from '../../../utilities/errors';
import {GET_POINTS_START, GET_POINTS_SUCCESS, GET_POINTS_FAIL, GET_POINTS_RESET} from './constants';
import {GetPointsStartAction, GetPointsSuccessAction, GetPointsFailAction, GetPointsResetAction} from './types';
import {getPointsRequest} from './repository';
import {PointDto} from '../../../models/dtos/point.dto';

const getPointsStartAction = (): GetPointsStartAction => {
  return {
    type: GET_POINTS_START,
  };
};
const getPointsSuccessAction = (payload: PointDto[]): GetPointsSuccessAction => {
  return {
    type: GET_POINTS_SUCCESS,
    payload: payload,
  };
};
const getPointsFailAction = (error: ErrorDto): GetPointsFailAction => {
  return {
    type: GET_POINTS_FAIL,
    error: error,
  };
};
const getPointsResetAction = (): GetPointsResetAction => {
  return {
    type: GET_POINTS_RESET,
  };
};

export const getPoints = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getPointsStartAction());
    const response = await getPointsRequest();
    dispatch(getPointsSuccessAction(response));
  } catch (error) {
    dispatch(getPointsFailAction(Errors.getErrorDtoFromApiError(error)));
  }
};

export const getPointsReset = () => (dispatch: Dispatch) => {
  dispatch(getPointsResetAction());
};
