import Adjust from "@adjustcom/adjust-web-sdk";
import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/remote-config';
import { Faq } from '../../models/custom/faq'
import { NotificationDto } from "../../models/dtos/notification.dto";
import { Helpers } from '../../utilities/helpers';

export default class FirebaseService {
  private static readonly config = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
    projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
  };

  private static readonly firebaseApp: firebase.app.App = firebase.initializeApp(FirebaseService.config);
  private static readonly analytics: firebase.analytics.Analytics = FirebaseService.firebaseApp.analytics();
  private static readonly remoteConfig: firebase.remoteConfig.RemoteConfig = FirebaseService.firebaseApp.remoteConfig();

  constructor() {
    FirebaseService.remoteConfig.defaultConfig = {
      test_key: 'test_key',
    };
  }

  public async fetchAndActivateRemoteConfig(): Promise<void> {
    await FirebaseService.firebaseApp
      .remoteConfig()
      .fetchAndActivate();
  }

  public static getValue(key: string): string | undefined {
    const value = this.remoteConfig.getValue(key).asString();
    return value.trim().length > 0 ? value.trim() : undefined;
  }

  public static getNotifications(): NotificationDto[] {
    const notifications = JSON.parse(this.getValue(this.notification_params) ?? '[]') as NotificationDto[];
    return notifications.sort((a, b) => a.date > b.date ? -1 : 1);
  }

  public static getContactSubjects(): string[] {
    const contactSubjects = this.getValue(this.contact_us_subjects) as string;
    if (contactSubjects) {
      return contactSubjects.split('|');
    }
    return [];
  }

  public static getFaqs(): Faq[] {
    const faqs: Faq[] = [];
    const questions = (this.getValue(this.faq_questions) as string).split('|');
    const answers = (this.getValue(this.faq_answers) as string).split('|');
    questions.forEach((questions, i) => faqs.push({ questions, answers: answers[i] }));
    return faqs;
  }

  public static logEvent(key: string): void {
    if (!Helpers.isEnvProd()) {
      console.log(`>> EVENT: ${key}`);
    }
    this.analytics.logEvent(key);
    this.sendAdjustEventIfAvailable(key);
  }

  private static sendAdjustEventIfAvailable(fbEventKey: string): void {
    let adjustId = '';
    switch (fbEventKey) {
      case FirebaseService.landing_view_without_login:
        adjustId = 'qmwxoo';
        break;
      case FirebaseService.landing_view_with_login:
        adjustId = 'e64wm7';
        break;
      case FirebaseService.first_gain_pop_up_view:
        adjustId = 'd9xap4';
        break;
      case FirebaseService.sign_up_view:
        adjustId = '657dvc';
        break;
      case FirebaseService.sign_up_continue_clicked:
        adjustId = 'yidjcs';
        break;
      case FirebaseService.sign_up_login_clicked:
        adjustId = '5fzote';
        break;
      case FirebaseService.sign_up_otp_view:
        adjustId = '2gtsba';
        break;
      case FirebaseService.sign_up_otp_continue_clicked:
        adjustId = 'obr3bu';
        break;
      case FirebaseService.sign_up_success_view:
        adjustId = '568l1j';
        break;
      case FirebaseService.sign_up_success_continue_clicked:
        adjustId = '4qk0wn';
        break;
      case FirebaseService.login_view:
        adjustId = '2724ts';
        break;
      case FirebaseService.login_continue_clicked:
        adjustId = 'x5y8ht';
        break;
      case FirebaseService.login_sign_up_clicked:
        adjustId = '75jx5b';
        break;
      case FirebaseService.login_otp_view:
        adjustId = 'hcwovc';
        break;
      case FirebaseService.login_otp_continue_clicked:
        adjustId = 'ikcd3i';
        break;
      case FirebaseService.sign_up_clicked:
        adjustId = 'kqz3jd';
        break;
      case FirebaseService.login_clicked:
        adjustId = 'y9txz5';
        break;
      case FirebaseService.my_account_clicked:
        adjustId = 'ky88dx';
        break;
      case FirebaseService.campaigns_clicked:
        adjustId = 'oxt3am';
        break;
      case FirebaseService.notifications_view:
        adjustId = 'imiebw';
        break;
      case FirebaseService.calculator_view:
        adjustId = 'xy49ex';
        break;
      case FirebaseService.how_it_works_hamburger_clicked:
        adjustId = 'wbhjd6';
        break;
      case FirebaseService.how_it_works_clicked:
        adjustId = 'z3fmro';
        break;
      case FirebaseService.faq_view:
        adjustId = 'eu8qgy';
        break;
      case FirebaseService.contact_us_view:
        adjustId = 'uh24op';
        break;
      case FirebaseService.cookie_switch_on:
        adjustId = 'kzvdgj';
        break;
      case FirebaseService.cookie_switch_off:
        adjustId = '4htbw7';
        break;
      case FirebaseService.how_it_works_page_1_view:
        adjustId = 'is7f8e';
        break;
      case FirebaseService.how_it_works_page_2_view:
        adjustId = 'mjwirh';
        break;
      case FirebaseService.how_it_works_page_3_view:
        adjustId = '8041cp';
        break;
      case FirebaseService.all_campaigns_view:
        adjustId = 'j4myth';
        break;
      case FirebaseService.filter_by_highest_cashback_rate:
        adjustId = 'pdyd71';
        break;
      case FirebaseService.detail_view_total:
        adjustId = '3il3vb';
        break;
      case FirebaseService.detail_view_total_with_login:
        adjustId = 'y05nt7';
        break;
      case FirebaseService.go_to_site_clicked_total:
        adjustId = 'urhufo';
        break;
      case FirebaseService.go_to_site_clicked_total_with_login:
        adjustId = 'z21so0';
        break;
      case FirebaseService.my_account_view:
        adjustId = 'funq5i';
        break;
      case FirebaseService.my_account_campaigns_clicked:
        adjustId = 'hnynnv';
        break;
      case FirebaseService.my_account_wallet_clicked:
        adjustId = 'tbnq6k';
        break;
      case FirebaseService.my_account_edit_profile_clicked:
        adjustId = 'unns3c';
        break;
      case FirebaseService.landing_app_store_clicked:
        adjustId = 'wc3eh5';
        break;
      case FirebaseService.landing_play_store_clicked:
        adjustId = 'wwj29e';
        break;
    }
    if (adjustId) {
      if (!Helpers.isEnvProd()) {
        console.log(`>> ADJUST: ${adjustId}`);
      }
      this.adjustEvent(adjustId);
    }
  }

  private static adjustEvent(eventId: string): void {
    Adjust.trackEvent({
      eventToken: eventId,
    })
  }

  // KEYS
  public static readonly test_key = 'test_key';
  public static readonly calculator_page_desc = 'calculator_page_desc';
  public static readonly calculator_page_title = 'calculator_page_title';
  public static readonly calculator_ph1 = 'calculator_ph1';
  public static readonly calculator_ph2 = 'calculator_ph2';
  public static readonly calculator_ph3 = 'calculator_ph3';
  public static readonly landing_campaigns_title = 'landing_campaigns_title';
  public static readonly landing_how_does_it_work_desc = 'landing_how_does_it_work_desc';
  public static readonly landing_how_does_it_work_title = 'landing_how_does_it_work_title';
  public static readonly landing_kgbapp_section_desc = 'landing_kgbapp_section_desc';
  public static readonly landing_kgbapp_section_title = 'landing_kgbapp_section_title';
  public static readonly landing_main_desc = 'landing_main_desc';
  public static readonly landing_main_title = 'landing_main_title';
  public static readonly contact_us_subjects = 'contact_us_subjects';
  public static readonly error_page_button = 'error_page_button';
  public static readonly error_page_desc = 'error_page_desc';
  public static readonly error_page_title = 'error_page_title';
  public static readonly error_popup_close_button = 'error_popup_close_button';
  public static readonly error_popup_desc = 'error_popup_desc';
  public static readonly error_popup_header = 'error_popup_header';
  public static readonly error_popup_try_again_button = 'error_popup_try_again_button';
  public static readonly notifications_header = 'notifications_header';
  public static readonly notification_params = 'notification_params';

  public static readonly faq_questions = 'faq_questions'
  public static readonly faq_answers = 'faq_answers'

  public static readonly onb_step1_title = 'onb_step1_title'
  public static readonly onb_step1_desc = 'onb_step1_desc'
  public static readonly onb_step1_button = 'onb_step1_button'

  public static readonly onb_step2_title = 'onb_step2_title'
  public static readonly onb_step2_desc = 'onb_step2_desc'
  public static readonly onb_step2_button = 'onb_step2_button'

  public static readonly onb_step3_title = 'onb_step3_title'
  public static readonly onb_step3_desc = 'onb_step3_desc'
  public static readonly onb_step3_button = 'onb_step3_button'

  public static readonly calculator_popup_title = 'calculator_popup_title';
  public static readonly calculator_popup_desc = 'calculator_popup_desc';
  public static readonly calculator_popup_ph1 = 'calculator_popup_ph1';
  public static readonly calculator_popup_ph2 = 'calculator_popup_ph2';
  public static readonly calculator_popup_ph3 = 'calculator_popup_ph3';

  // EVENTS
  public static readonly landing_view_without_login = 'landing_view_without_login';
  public static readonly landing_view_with_login = 'landing_view_with_login';
  public static readonly first_gain_pop_up_view = 'first_gain_pop_up_view';
  public static readonly sign_up_view = 'sign_up_view';
  public static readonly sign_up_continue_clicked = 'sign_up_continue_clicked';
  public static readonly sign_up_login_clicked = 'sign_up_login_clicked';
  public static readonly sign_up_otp_view = 'sign_up_otp_view';
  public static readonly sign_up_otp_continue_clicked = 'sign_up_otp_continue_clicked';
  public static readonly sign_up_success_view = 'sign_up_success_view';
  public static readonly sign_up_success_continue_clicked = 'sign_up_success_continue_clicked';
  public static readonly login_view = 'login_view';
  public static readonly login_continue_clicked = 'login_continue_clicked';
  public static readonly login_sign_up_clicked = 'login_sign_up_clicked';
  public static readonly login_otp_view = 'login_otp_view';
  public static readonly login_otp_continue_clicked = 'login_otp_continue_clicked';
  public static readonly sign_up_clicked = 'sign_up_clicked';
  public static readonly login_clicked = 'login_clicked';
  public static readonly my_account_clicked = 'my_account_clicked';
  public static readonly campaigns_clicked = 'campaigns_clicked';
  public static readonly notifications_view = 'notifications_view';
  public static readonly calculator_view = 'calculator_view';
  public static readonly how_it_works_hamburger_clicked = 'how_it_works_hamburger_clicked';
  public static readonly how_it_works_clicked = 'how_it_works_clicked';
  public static readonly faq_view = 'faq_view';
  public static readonly contact_us_view = 'contact_us_view';
  public static readonly cookie_switch_on = 'cookie_switch_on';
  public static readonly cookie_switch_off = 'cookie_switch_off';
  public static readonly how_it_works_page_1_view = 'how_it_works_page_1_view';
  public static readonly how_it_works_page_2_view = 'how_it_works_page_2_view';
  public static readonly how_it_works_page_3_view = 'how_it_works_page_3_view';
  public static readonly all_campaigns_view = 'all_campaigns_view';
  public static readonly filter_by_highest_cashback_rate = 'filter_by_highest_cashback_rate';
  public static readonly filter_by_lowest_cashback_rate = 'filter_by_lowest_cashback_rate';
  public static readonly filter_by_highest_time_left = 'filter_by_highest_time_left';
  public static readonly filter_by_lowest_tiime_left = 'filter_by_lowest_tiime_left';
  public static readonly detail_view_total = 'detail_view_total';
  public static readonly detail_view_total_with_login = 'detail_view_total_with_login';
  public static readonly go_to_site_clicked_total = 'go_to_site_clicked_total';
  public static readonly go_to_site_clicked_total_with_login = 'go_to_site_clicked_total_with_login';
  public static readonly my_account_view = 'my_account_view';
  public static readonly my_account_campaigns_clicked = 'my_account_campaigns_clicked';
  public static readonly my_account_wallet_clicked = 'my_account_wallet_clicked';
  public static readonly my_account_edit_profile_clicked = 'my_account_edit_profile_clicked';
  public static readonly my_account_changes_saved_view = 'my_account_changes_saved_view';
  public static readonly sign_out_popup_view = 'sign_out_popup_view';
  public static readonly sign_out_on_popup_clicked = 'sign_out_on_popup_clicked';
  public static readonly landing_app_store_clicked = 'landing_app_store_clicked';
  public static readonly landing_play_store_clicked = 'landing_play_store_clicked';
}
