import {Dispatch} from 'redux';
import {ErrorDto} from '../../../models/dtos/error.dto';
import {TokenDto} from '../../../models/dtos/token.dto';
import {LoginRequest} from '../../../models/requests/login.request';
import {Errors} from '../../../utilities/errors';
import {history} from "../../../utilities/history";
import {LocalStorage} from "../../../utilities/localStorage";
import {router} from "../../../utilities/router";
import {getMeRequest} from "../../userService/getMe/repository";
import {LOGIN_FAIL, LOGIN_RESET, LOGIN_START, LOGIN_SUCCESS} from './constants';
import {loginRequest} from './repository';
import {LoginFailAction, LoginResetAction, LoginStartAction, LoginSuccessAction} from './types';

const loginStartAction = (): LoginStartAction => {
  return {
    type: LOGIN_START,
  };
};
const loginSuccessAction = (payload: TokenDto): LoginSuccessAction => {
  return {
    type: LOGIN_SUCCESS,
    payload: payload,
  };
};
const loginFailAction = (error: ErrorDto): LoginFailAction => {
  return {
    type: LOGIN_FAIL,
    error: error,
  };
};
const loginResetAction = (): LoginResetAction => {
  return {
    type: LOGIN_RESET,
  };
};

export const login = (request: LoginRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(loginStartAction());
    const response = await loginRequest(request);
    LocalStorage.set(LocalStorage.token, response.token);
    await getMeRequest(dispatch);
    dispatch(loginSuccessAction(response));
  } catch (error) {
    const errorDto = Errors.getParsedErrorDtoFromApiError(error);
    if (errorDto.status === 401) {
      history.push(router.SIGNUP);
    }
    dispatch(loginFailAction(Errors.getErrorDtoFromApiError(error)));
  }
};

export const loginReset = () => (dispatch: Dispatch) => {
  dispatch(loginResetAction());
};
