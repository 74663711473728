import {Dispatch} from 'redux';
import {ErrorDto} from '../../../models/dtos/error.dto';
import {Errors} from '../../../utilities/errors';
import {getMeRequest} from "../getMe/repository";
import {POPUP_SHOWN_FAIL, POPUP_SHOWN_RESET, POPUP_SHOWN_START, POPUP_SHOWN_SUCCESS} from './constants';
import {popupShownRequest} from "./repository";
import {PopupShownFailAction, PopupShownResetAction, PopupShownStartAction, PopupShownSuccessAction} from './types';

const popupShownStartAction = (): PopupShownStartAction => {
  return {
    type: POPUP_SHOWN_START,
  };
};
const popupShownSuccessAction = (payload: boolean): PopupShownSuccessAction => {
  return {
    type: POPUP_SHOWN_SUCCESS,
    payload: payload,
  };
};
const popupShownFailAction = (error: ErrorDto): PopupShownFailAction => {
  return {
    type: POPUP_SHOWN_FAIL,
    error: error,
  };
};
const popupShownResetAction = (): PopupShownResetAction => {
  return {
    type: POPUP_SHOWN_RESET,
  };
};

export const popupShown = () => async (dispatch: Dispatch) => {
  try {
    dispatch(popupShownStartAction());
    await popupShownRequest();
    await getMeRequest(dispatch);
    dispatch(popupShownSuccessAction(true));
  } catch (error) {
    dispatch(popupShownFailAction(Errors.getErrorDtoFromApiError(error)));
  }
};

export const popupShownReset = () => (dispatch: Dispatch) => {
  dispatch(popupShownResetAction());
};
