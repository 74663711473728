import axios from '../../../utilities/axios';

export const popupShownRequest = async (): Promise<void> => {
  try {
    const response = await axios.patch<void>(
      `${process.env.REACT_APP_BASE_URL}/user/me/pop-up-shown`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
