import {Carousel} from "antd";
import React from "react";
import {history} from "../../../../core/utilities/history";
import {router} from "../../../../core/utilities/router";
import "./SectionSlider.scss";

interface IProps {
  images: string[];
}

const SectionSlider = (props: IProps) => {
  
  return (
    <Carousel
      className="section-slider"
      autoplay
    >
      {
        props.images.map(image => {
          return (
            <div key={image} className="image-wrapper">
              <div
                className="image"
                style={{
                  background: `url("${image}") no-repeat center`,
                  backgroundSize: "cover",
                  cursor: 'pointer',
                }}
                onClick={() => history.push(router.CAMPAIGNS)}
              />
            </div>
          );
        })
      }
    </Carousel>
  );
}

export default SectionSlider;
