import React from 'react';
import AppFooter from "../../AppFooter/AppFooter";
import AppHeader from "../../AppHeader/AppHeader";
import './AppLayout.scss';

class AppLayout extends React.Component {

  render() {
    return (
      <div id="app-layout">
        <AppHeader/>
        {this.props.children}
        <AppFooter/>
      </div>
    );
  }
}

export default AppLayout;
