import {Dispatch} from 'redux';
import {CampaignDto} from '../../../models/dtos/campaign.dto';
import {ErrorDto} from '../../../models/dtos/error.dto';
import {Errors} from '../../../utilities/errors';
import {GET_CAMPAIGNS_FAIL, GET_CAMPAIGNS_RESET, GET_CAMPAIGNS_START, GET_CAMPAIGNS_SUCCESS} from './constants';
import {getCampaignsRequest} from "./repository";
import {
  GetCampaignsFailAction,
  GetCampaignsResetAction,
  GetCampaignsStartAction,
  GetCampaignsSuccessAction
} from './types';

const getCampaignsStartAction = (): GetCampaignsStartAction => {
  return {
    type: GET_CAMPAIGNS_START,
  };
};
const getCampaignsSuccessAction = (payload: CampaignDto[]): GetCampaignsSuccessAction => {
  return {
    type: GET_CAMPAIGNS_SUCCESS,
    payload: payload,
  };
};
const getCampaignsFailAction = (error: ErrorDto): GetCampaignsFailAction => {
  return {
    type: GET_CAMPAIGNS_FAIL,
    error: error,
  };
};
const getCampaignsResetAction = (): GetCampaignsResetAction => {
  return {
    type: GET_CAMPAIGNS_RESET,
  };
};

export const getCampaigns = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getCampaignsStartAction());
    const response = await getCampaignsRequest();
    dispatch(getCampaignsSuccessAction(response));
  } catch (error) {
    dispatch(getCampaignsFailAction(Errors.getErrorDtoFromApiError(error)));
  }
};

export const getCampaignsReset = () => (dispatch: Dispatch) => {
  dispatch(getCampaignsResetAction());
};
