import React from "react";
import {isSafari} from "react-device-detect";
import downloadApple from "../../../../assets/images/download-app-store.svg"
import downloadGoogle from "../../../../assets/images/download-google-play.svg"
import kgbApp from "../../../../assets/images/kgb-app-iPhone.png";
import arrow from "../../../../assets/images/poly-kgb-app-arrow.svg";
import gb from "../../../../assets/images/poly-kgb-app-gb.svg";
import gift from "../../../../assets/images/poly-kgb-app-gift.svg";
import wallet from "../../../../assets/images/poly-kgb-app-wallet.svg";
import CookieAgreement from "../../../../components/CookieAgreement/CookieAgreement";
import FirebaseKey from "../../../../components/FirebaseKey/FirebaseKey";
import FirebaseService from "../../../../core/services/firebaseService/firebase.service";
import {Constants} from "../../../../core/utilities/constants";
import "./SectionKgbApp.scss";

interface IProps {
}

const SectionKgbApp = (props: IProps) => {
  
  return (
    <section
      id="section-kgb-app"
      className="section"
    >
      <div className="content">
        <FirebaseKey
          className="section-title"
          firebaseKey={FirebaseService.landing_kgbapp_section_title}
        />
        <FirebaseKey
          className="section-description"
          firebaseKey={FirebaseService.landing_kgbapp_section_desc}
        />
        <div className="topics">
          <div className="topic gb">
            <img className="icon" src={gb} alt="gb"/>
            <span className="text">
              MetaByte’larını dijital faydalara ve internet paketine dönüştürebilirsin.
            </span>
          </div>
          <div className="topic">
            <img src={gift} alt="gift"/>
            <span className="text">
              Oyun oynayarak MetaByte kazanabilirsin.
            </span>
          </div>
          <div className="topic">
            <img src={wallet} alt="wallet"/>
            <span className="text">
              İhtiyacın olana kadar MetaByte’larını biriktirebilirsin.
            </span>
          </div>
          <div className="topic">
            <img src={arrow} alt="arrow"/>
            <span className="text">
              Sevdiklerine MetaByte gönderebilirsin.
            </span>
          </div>
        </div>
      </div>
      <div className="kgb-app">
        <img className="phone" src={kgbApp} alt="app"/>
        <div className="store-wrapper">
          <span className="title">Ücretsiz İndir</span>
          <div className="links">
            <a
              href={Constants.linkAppStore} rel="noreferrer" target="_blank"
              onClick={() => FirebaseService.logEvent(FirebaseService.landing_app_store_clicked)}
            >
              <img src={downloadApple} alt="app store"/>
            </a>
            <a
              href={Constants.linkGooglePlay} rel="noreferrer" target="_blank"
              onClick={() => FirebaseService.logEvent(FirebaseService.landing_play_store_clicked)}
            >
              <img src={downloadGoogle} alt="google play"/>
            </a>
          </div>
        </div>
      </div>
      {!isSafari && <CookieAgreement/>}
    </section>
  );
}

export default SectionKgbApp;
