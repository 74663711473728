import {SIGN_UP_OTP_START, SIGN_UP_OTP_SUCCESS, SIGN_UP_OTP_FAIL, SIGN_UP_OTP_RESET} from './constants';
import {SignUpOtpActions, SignUpOtpState} from './types';

const initialState: SignUpOtpState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: SignUpOtpActions,
): SignUpOtpState => {
  switch (action.type) {
    case SIGN_UP_OTP_START:
      return {
        ...initialState,
        loading: true,
      };
    case SIGN_UP_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case SIGN_UP_OTP_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SIGN_UP_OTP_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
