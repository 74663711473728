import { Button } from "antd";
import { ReactNode, useState } from "react";
import calculator from "../../../../assets/images/calculator.svg";
import CalculatorModal from "../../../../components/Modals/CalculatorModal/CalculatorModal";

import "./Calculator.scss";


interface IProps {
}

const Calculator = (props: IProps) => {
  const [isCalculatorModalVisible, setIsCalculatorModalVisible] = useState(false);

  function renderBtn(): ReactNode {
    return (
      <Button
        className="app-button calculator-button p-0"
        icon={<img src={calculator} alt="calculator" />}
        onClick={() => setIsCalculatorModalVisible(!isCalculatorModalVisible)}
      />
    );
  }

  return (
    <div id="calculator">
      <CalculatorModal
        isVisible={isCalculatorModalVisible}
        callbackClose={() => setIsCalculatorModalVisible(false)} />
      {renderBtn()}
    </div>
  );
}

export default Calculator;
