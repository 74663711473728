export const router = {
  LANDING: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  CAMPAIGNS: '/campaigns',
  ACCOUNT: '/account',
  PROFILE: '/profile',
  HOW_IT_WORKS: '/how-it-works',
  CONTACT_US: '/contact-us',
  FAQ: '/faq',
  NOTIFICATIONS: '/notifications',
  CALCULATOR: '/calculator',
  NOT_FOUND: '/not-found',
};
