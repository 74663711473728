import {Dispatch} from "redux";
import {MeDto} from '../../../models/dtos/me.dto';
import axios from '../../../utilities/axios';
import {setMeAction} from "../../appService/setMe/actions";

export const getMeRequest = async (dispatch?: Dispatch): Promise<MeDto> => {
  try {
    const response = await axios.get<MeDto>(
      `${process.env.REACT_APP_BASE_URL}/user/me`,
    );
    if (dispatch) {
      dispatch(setMeAction(response.data));
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};
