import {SET_UNREAD_NOTIFICATION_IDS} from "./constants";
import {SetUnreadNotificationIdsActions, SetUnreadNotificationIdsState} from "./types";

const initialState: SetUnreadNotificationIdsState = {
  data: undefined,
};

const reducer = (
  state = initialState,
  action: SetUnreadNotificationIdsActions,
): SetUnreadNotificationIdsState => {
  switch (action.type) {
    case SET_UNREAD_NOTIFICATION_IDS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
