import { NavLink } from 'react-router-dom';
import appStore from "../../assets/images/app-store.svg";
import logo from "../../assets/images/logo-footer.svg";
import { Constants } from "../../core/utilities/constants";
import { router } from "../../core/utilities/router";
import "./AppFooter.scss";

interface IProps {
}

const AppFooter = (props: IProps) => {
  return (
    <div id="app-footer">
      <NavLink to={router.LANDING}>
        <img src={logo} alt="logo" />
      </NavLink>
      <div className="download-wrapper">
        <a href={Constants.linkAppStore} rel="noreferrer" target="_blank">
          <img src={appStore} alt="store" />
        </a>
      </div>
    </div>
  );
}

export default AppFooter;
