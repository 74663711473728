import React from "react";
import poly from "../../assets/images/poly-transparent.svg";
import "./DiscountPoly.scss";

interface IProps {
  discount: number;
}

const DiscountPoly = (props: IProps) => {

  return (
    <div id="discount-poly">
      <img className="poly" src={poly} alt="poly"/>
      <span className="discount">%{props.discount}</span>
    </div>
  );
}

export default DiscountPoly;
