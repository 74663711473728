import {CombinedState, combineReducers, Reducer} from 'redux';
import checkTokenReducer from '../services/appService/checkToken/reducer';
import {CheckTokenState} from '../services/appService/checkToken/types';
import setApiErrorReducer from '../services/appService/setApiError/reducer';
import {SetApiErrorState} from '../services/appService/setApiError/types';
import setAppMountedReducer from '../services/appService/setAppMounted/reducer';
import {SetAppMountedState} from '../services/appService/setAppMounted/types';
import setCampaignFilterReducer from '../services/appService/setCampaignFilter/reducer';
import {SetCampaignFilterState} from '../services/appService/setCampaignFilter/types';
import setCookieAgreementReducer from '../services/appService/setCookieAgreement/reducer';
import {SetCookieAgreementState} from '../services/appService/setCookieAgreement/types';
import setMeReducer from '../services/appService/setMe/reducer';
import {SetMeState} from '../services/appService/setMe/types';
import setPathnameReducer from '../services/appService/setPathname/reducer';
import {SetPathnameState} from '../services/appService/setPathname/types';
import setUnreadNotificationIdsReducer from '../services/appService/setUnreadNotificationIds/reducer';
import {SetUnreadNotificationIdsState} from '../services/appService/setUnreadNotificationIds/types';
import loginReducer from '../services/authService/login/reducer';
import {LoginState} from '../services/authService/login/types';
import loginOtpReducer from '../services/authService/loginOtp/reducer';
import {LoginOtpState} from '../services/authService/loginOtp/types';
import signUpReducer from '../services/authService/signUp/reducer';
import {SignUpState} from '../services/authService/signUp/types';
import signUpOtpReducer from '../services/authService/signUpOtp/reducer';
import {SignUpOtpState} from '../services/authService/signUpOtp/types';
import getCampaignsReducer from '../services/campaignService/getCampaigns/reducer';
import {GetCampaignsState} from '../services/campaignService/getCampaigns/types';
import redirectCampaignReducer from '../services/campaignService/redirectCampaign/reducer';
import {RedirectCampaignState} from '../services/campaignService/redirectCampaign/types';
import contactReducer from '../services/contactService/contact/reducer';
import {ContactState} from '../services/contactService/contact/types';
import setRemoteConfigReducer from '../services/firebaseService/setRemoteConfig/reducer';
import {SetRemoteConfigState} from '../services/firebaseService/setRemoteConfig/types';
import getMeReducer from '../services/userService/getMe/reducer';
import {GetMeState} from '../services/userService/getMe/types';
import getPointsReducer from '../services/userService/getPoints/reducer';
import {GetPointsState} from '../services/userService/getPoints/types';
import popupShownReducer from '../services/userService/popupShown/reducer';
import {PopupShownState} from '../services/userService/popupShown/types';
import updateMeReducer from '../services/userService/updateMe/reducer';
import {UpdateMeState} from '../services/userService/updateMe/types';

export interface IStore {
  // APP
  checkToken: CheckTokenState;
  setApiError: SetApiErrorState;
  setAppMounted: SetAppMountedState;
  setCampaignFilter: SetCampaignFilterState;
  setCookieAgreement: SetCookieAgreementState;
  setMe: SetMeState;
  setPathname: SetPathnameState;
  setUnreadNotificationIds: SetUnreadNotificationIdsState;
  // AUTH
  login: LoginState;
  loginOtp: LoginOtpState;
  signUp: SignUpState;
  signUpOtp: SignUpOtpState;
  // CAMPAIGN
  getCampaigns: GetCampaignsState;
  redirectCampaign: RedirectCampaignState;
  // CONTACT
  contact: ContactState;
  // FIREBASE
  setRemoteConfig: SetRemoteConfigState;
  // USER
  getMe: GetMeState;
  getPoints: GetPointsState;
  popupShown: PopupShownState;
  updateMe: UpdateMeState;
}

export const rootReducer: Reducer<CombinedState<IStore>> = combineReducers({
  // APP
  checkToken: checkTokenReducer,
  setApiError: setApiErrorReducer,
  setAppMounted: setAppMountedReducer,
  setCampaignFilter: setCampaignFilterReducer,
  setCookieAgreement: setCookieAgreementReducer,
  setMe: setMeReducer,
  setPathname: setPathnameReducer,
  setUnreadNotificationIds: setUnreadNotificationIdsReducer,
  // AUTH
  login: loginReducer,
  loginOtp: loginOtpReducer,
  signUp: signUpReducer,
  signUpOtp: signUpOtpReducer,
  // CAMPAIGN
  getCampaigns: getCampaignsReducer,
  redirectCampaign: redirectCampaignReducer,
  // CONTACT
  contact: contactReducer,
  // FIREBASE
  setRemoteConfig: setRemoteConfigReducer,
  // USER
  getMe: getMeReducer,
  getPoints: getPointsReducer,
  popupShown: popupShownReducer,
  updateMe: updateMeReducer,
})
