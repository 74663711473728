import React, {ReactNode} from "react";
import sandGlass from "../../assets/images/sand-glass.svg";
import {Helpers} from "../../core/utilities/helpers";
import "./CampaignTimeLeft.scss";

interface IProps {
  className?: string;
  endDate: string;
}

const CampaignTimeLeft = (props: IProps) => {

  function getCounter(): ReactNode {
    const dayDiff = Helpers.getDaysDiffWithToday(props.endDate);
    return `Son ${dayDiff > 0 ? dayDiff : ''} gün`;
  }

  return (
    <div id="campaign-time-left" className={props.className ?? ''}>
      <img className="icon" src={sandGlass} alt="sand glass"/>
      <span className="counter">
        {getCounter()}
      </span>
    </div>
  );
}

export default CampaignTimeLeft;
