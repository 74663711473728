import {Button, Modal} from "antd";
import React from 'react';
import {UI} from "../../../core/utilities/ui";
import "./SafariTrackerWarningModal.scss";

interface IProps {
  campaignId: number;
  callback: (campaignId?: number) => void;
}

const SafariTrackerWarningModal = (props: IProps) => {
  
  return (
    <Modal
      wrapClassName="safari-tracker-warning-modal-wrapper"
      className="safari-tracker-warning-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
      onCancel={() => props.callback(undefined)}
    >
      <div className="content">
        <label className="title">
          Lütfen tarayıcını değiştir!
        </label>
        <div className="line-wrapper d-none d-md-flex">
          <span className="line"/>
        </div>
        <label className="text">
          MetaByte Kazan’dan en iyi şekilde yararlanabilmen için çerezler kullanıyoruz. Safari tarayıcısı otomatik
          olarak çerezleri engellediği için alışverişlerine farklı bir tarayıcı ile devam et.
        </label>
        <Button
          className="app-button ok"
          onClick={() => props.callback(props.campaignId)}
        >
          Tamam
        </Button>
      </div>
    </Modal>
  );
}

export default SafariTrackerWarningModal;
