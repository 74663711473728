import {TokenDto} from '../../../models/dtos/token.dto';
import {SignUpRequest} from "../../../models/requests/signUp.request";
import axios from '../../../utilities/axios';

export const signUpRequest = async (request: SignUpRequest): Promise<TokenDto> => {
  try {
    const response = await axios.post<TokenDto>(
      `${process.env.REACT_APP_BASE_URL}/auth/sign-up`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
