import React from "react";
import DiscountPoly from "../DiscountPoly/DiscountPoly";
import "./CampaignDiscount.scss";

interface IProps {
  imageUrl: string;
  discount: number;
}

const CampaignDiscount = (props: IProps) => {

  return (
    <div id="campaign-discount">
      <div
        className="image"
        style={{
          background: `url(${props.imageUrl ?? 'https://via.placeholder.com/128x64'}) no-repeat center`,
          backgroundSize: "cover"
        }}
      />
      <DiscountPoly discount={props.discount}/>
    </div>
  );
}

export default CampaignDiscount;
