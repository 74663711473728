import React from "react";
import approved from "../../../../assets/images/account-approved.svg";
import waiting from "../../../../assets/images/account-waiting.svg";
import {Helpers} from "../../../../core/utilities/helpers";
import "./AccountCard.scss";

interface IProps {
  isApproved: boolean;
  point?: number;
}

const AccountCard = (props: IProps) => {

  return (
    <div id="account-card">
      <img className="icon" src={props.isApproved ? approved : waiting} alt="icon"/>
      <span className="title">
        {props.isApproved ? 'Onaylandı' : 'Değerlendirmede'}
      </span>
      <span className="point">
        {(!!props.point || props.point === 0) ? `${Helpers.numberWithDotSeparator(props.point)} Puan` : '-'}
      </span>
    </div>
  );
}

export default AccountCard;
