import {Button, Modal} from "antd";
import React, {ReactNode} from 'react';
import {UI} from "../../../core/utilities/ui";
import Loading from "../../Loading/Loading";
import "./ProfileApprovalModal.scss";

interface IProps {
  isLoading: boolean;
  callback: (isApproved: boolean) => void;
}

const ProfileApprovalModal = (props: IProps) => {

  function renderButtonsMobile(): ReactNode {
    return (
      <div className="d-flex d-md-none button-wrapper mobile">
        <Button
          className="app-button ok"
          onClick={() => props.callback(true)}
        >
          Kaydet
        </Button>
        <Button
          className="app-button cancel"
          onClick={() => props.callback(false)}
        >
          Vazgeç
        </Button>
      </div>
    );
  }

  function renderButtonsDesktop(): ReactNode {
    return (
      <div className="d-none d-md-flex button-wrapper">
        <Button
          className="app-button cancel"
          onClick={() => props.callback(false)}
        >
          Vazgeç
        </Button>
        <Button
          className="app-button ok"
          onClick={() => props.callback(true)}
        >
          Kaydet
        </Button>
      </div>
    );
  }

  return (
    <Modal
      wrapClassName="profile-approval-modal-wrapper"
      className="profile-approval-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
      onCancel={() => props.callback(false)}
    >
      <div className="content">
        <label className="title">
        Hesabını güncelliyorsun.
        </label>
        <div className="line-wrapper d-none d-md-flex">
          <span className="line"/>
        </div>
        <label className="text">
          Yaptığın değişiklikleri kaydetmek istiyor musun?
        </label>
        {
          props.isLoading
            ?
            <div className="loading-wrapper">
              <Loading fontSize={36}/>
            </div>
            :
            <React.Fragment>
              {renderButtonsMobile()}
              {renderButtonsDesktop()}
            </React.Fragment>
        }
      </div>
    </Modal>
  );
}

export default ProfileApprovalModal;
