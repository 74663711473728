import {Button, Typography} from "antd";
import parse from "html-react-parser";
import {ReactNode} from "react";
import arrow from "../../../../assets/images/arrow-external-link.svg";
import CampaignDiscount from "../../../../components/CampaignDiscount/CampaignDiscount";
import CampaignTimeLeft from "../../../../components/CampaignTimeLeft/CampaignTimeLeft";
import {CampaignDto} from "../../../../core/models/dtos/campaign.dto";
import {MeDto} from "../../../../core/models/dtos/me.dto";
import "./CampaignCard.scss";

const {Paragraph} = Typography;

interface IProps {
  me?: MeDto;
  campaign: CampaignDto;
  isRedirectLoading: boolean;
  callbackDetailPopup: (campaign: CampaignDto) => void;
  callbackRedirectSite: (campaign: CampaignDto) => void;
}

const CampaignCard = (props: IProps) => {
  
  function renderContent(): ReactNode {
    return (
      <div className="content">
        <h3
          className="title"
          onClick={() => props.callbackRedirectSite(props.campaign)}
        >
          {props.campaign.title}
        </h3>
        <Paragraph
          className="description"
          ellipsis={{rows: 3}}
        >
          {parse(props.campaign.description)}
        </Paragraph>
        <div className="actions">
          <CampaignTimeLeft endDate={props.campaign.end}/>
          <span
            className="details"
            onClick={() => props.callbackDetailPopup(props.campaign)}
          >
            Detaylar
          </span>
          <Button
            className="link"
            disabled={props.isRedirectLoading}
            onClick={() => props.callbackRedirectSite(props.campaign)}
          >
            <span>
              Siteye Git
              <img className="arrow" src={arrow} alt="arrow"/>
            </span>
          </Button>
        
        </div>
      </div>
    );
  }
  
  return (
    <div id="campaign-card">
      <div
        className="banner-image"
        style={{
          background: `url("${props.campaign.imageUrl}") no-repeat center`,
          backgroundSize: "cover"
        }}
        onClick={() => props.callbackRedirectSite(props.campaign)}
      />
      <div className="content-wrapper">
        <CampaignDiscount
          imageUrl={props.campaign.logoUrl}
          discount={props.campaign.discount}
        />
        {renderContent()}
      </div>
    </div>
  );
}

export default CampaignCard;
