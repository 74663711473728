import {Button, Modal} from 'antd';
import React from 'react';
import CampaignFilter from "../../../containers/App/CampaignsPage/CampaignFilter/CampaignFilter";
import {UI} from '../../../core/utilities/ui';
import './CampaignFilterModal.scss';

interface IProps {
  callbackClearFilter: () => void;
  callbackClose: () => void;
}

const CampaignFilterModal = (props: IProps) => {

  return (
    <Modal
      className="campaign-filter-modal"
      maskStyle={UI.modalMaskStyle()}
      visible
      closable={false}
      footer={false}
      centered
      destroyOnClose
      onCancel={props.callbackClose}
    >
      <div className="content">
        <CampaignFilter
          callbackClearFilter={props.callbackClearFilter}
        />
        <Button
          className="app-button"
          onClick={props.callbackClose}
        >
          Uygula
        </Button>
      </div>
    </Modal>
  );
};

export default CampaignFilterModal;
