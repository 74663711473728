import {Button} from "antd";
import React, {Component, ReactNode} from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {bindActionCreators, Dispatch} from "redux";
import Loading from "../../../components/Loading/Loading";
import {MeDto} from "../../../core/models/dtos/me.dto";
import {PointState} from "../../../core/models/enums/pointState";
import FirebaseService from "../../../core/services/firebaseService/firebase.service";
import {getPoints} from "../../../core/services/userService/getPoints/actions";
import {GetPointsState} from "../../../core/services/userService/getPoints/types";
import {Constants} from "../../../core/utilities/constants";
import {history} from "../../../core/utilities/history";
import {LocalStorage} from "../../../core/utilities/localStorage";
import {IStore} from "../../../core/utilities/reducers";
import {router} from "../../../core/utilities/router";
import AccountCard from "./AccountCard/AccountCard";
import "./AccountPage.scss";
import ProfileCard from "./ProfileCard/ProfileCard";
import WarningCard from "./WarningCard/WarningCard";

interface IProps {
  me?: MeDto;
  getPointsState: GetPointsState;
  getPoints: () => void;
}

class AccountPage extends Component<IProps> {
  
  private readonly profileWarningDisabledValue = 'false';
  
  componentDidMount() {
    FirebaseService.logEvent(FirebaseService.my_account_view);
    this.props.getPoints();
  }
  
  private handleWarningProfile(): void {
    LocalStorage.set(LocalStorage.profileWarning, this.profileWarningDisabledValue);
    this.setState({}); // for rerender
  }
  
  private renderWarningCardProfile(): ReactNode {
    if (
      (!!this.props.me?.nameSurname && !!this.props.me?.email) ||
      (LocalStorage.get(LocalStorage.profileWarning) === this.profileWarningDisabledValue)
    ) {
      return <React.Fragment/>;
    }
    return (
      <WarningCard
        title="Henüz profil bilgilerini doldurmadın."
        text="Fırsatlardan haberdar olmak için profilini doldur."
        action={<span>Daha sonra</span>}
        callback={() => this.handleWarningProfile()}
      />
    );
  }
  
  private renderProfile(): ReactNode {
    return (
      <div className="profile order-0 order-md-1">
        <ProfileCard me={this.props.me!}/>
        <NavLink
          to={router.PROFILE}
          onClick={() => FirebaseService.logEvent(FirebaseService.my_account_edit_profile_clicked)}
        >
          <span className="link-profile">Düzenle</span>
        </NavLink>
        {this.renderWarningCardProfile()}
      </div>
    );
  }
  
  private renderAccount(): ReactNode {
    return (
      <div className="account order-1 order-md-0">
        {
          this.props.getPointsState.loading
            ?
            <Loading fontSize={48}/>
            :
            <React.Fragment>
              <span className="sub-title">
              Güncel Durumum
            </span>
              <div className="account-cards">
                {/* {this.props.getPointsState.data?.map(point => {
                  return (
                    <AccountCard
                      key={point.state}
                      isApproved={point.state === PointState.APPROVED}
                      point={point.amount}
                    />
                  );
                })} */}
                {
                  this.props.getPointsState.data?.map(point => {
                    if (point.state === PointState.PENDING) {
                      return (
                        <AccountCard
                          key={PointState.PENDING}
                          isApproved={false}
                          point={point.amount}
                        />
                      )
                    } else if (point.state === PointState.APPROVED) {
                      return (
                        <AccountCard
                          key={PointState.APPROVED}
                          isApproved={true}
                          point={point.amount}
                        />
                      )
                    }
                    return "";
                  })
                }
              </div>
              <span className="note">
                Burada değerlendirmede olan ve onaylanan tüm kazançlarını görebilirsin.
                Değerlendirmede olan kazançların onaylandığında MetaByte’ların dijital kumbarana da aktarılır.
              </span>
              <div className="button-wrapper">
                <Button
                  className="app-button order-0 order-md-1"
                  onClick={() => FirebaseService.logEvent(FirebaseService.my_account_wallet_clicked)}
                >
                  <a href={Constants.linkWallet} target="_blank" rel="noreferrer">
                    Dijital Kumbaram
                  </a>
                </Button>
                <Button
                  className="app-button outline order-1 order-md-0"
                  onClick={() => {
                    FirebaseService.logEvent(FirebaseService.my_account_campaigns_clicked);
                    history.push(router.CAMPAIGNS);
                  }}
                >
                  Fırsatlar
                </Button>
              </div>
            </React.Fragment>
        }
      </div>
    );
  }
  
  render() {
    return (
      <div id="account-page" className="page">
        <div className="page-content">
          {this.renderProfile()}
          {this.renderAccount()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getPoints,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    me: store.setMe.me,
    getPointsState: store.getPoints,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
