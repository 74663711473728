import {Button} from "antd";
import React, {Component, ReactNode} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import ProfileApprovalModal from "../../../components/Modals/ProfileApprovalModal/ProfileApprovalModal";
import {FormValuesProfile} from "../../../core/models/custom/formValuesProfile";
import {MeDto} from "../../../core/models/dtos/me.dto";
import {UpdateMeRequest} from "../../../core/models/requests/updateMe.request";
import {updateMe, updateMeReset} from "../../../core/services/userService/updateMe/actions";
import {UpdateMeState} from "../../../core/services/userService/updateMe/types";
import {Helpers} from "../../../core/utilities/helpers";
import {history} from "../../../core/utilities/history";
import {IStore} from "../../../core/utilities/reducers";
import {router} from "../../../core/utilities/router";
import ProfileForm from "./ProfileForm/ProfileForm";
import "./ProfilePage.scss";

interface IProps {
  me?: MeDto;
  updateMeState: UpdateMeState;
  updateMe: (request: UpdateMeRequest) => void;
  updateMeReset: () => void;
}

interface IState {
  profileForm?: FormValuesProfile;
}

class ProfilePage extends Component<IProps> {
  state: IState = {
    profileForm: undefined,
  }
  
  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (Helpers.isFreshData(prevProps.updateMeState, this.props.updateMeState)) {
      this.setProfileForm();
    }
  }
  
  private setProfileForm(profileForm?: FormValuesProfile): void {
    this.setState({profileForm});
  }
  
  private handleSubmit(values: FormValuesProfile): void {
    this.setProfileForm(values);
  }
  
  private handleApproval(isApproved: boolean): void {
    if (isApproved) {
      this.props.updateMe({
        nameSurname: this.state.profileForm?.nameSurname,
        email: this.state.profileForm?.email,
      });
      return;
    }
    this.setProfileForm();
  }
  
  private renderStepProfile(): ReactNode {
    return (
      <ProfileForm
        me={this.props.me}
        isLoading={this.props.updateMeState.loading}
        callbackSubmit={values => this.handleSubmit(values)}
      />
    );
  }
  
  private renderStepSuccess(): ReactNode {
    return (
      <div className="success-content">
        <div className="image d-flex d-md-none"/>
        <span className="title">
          Değişiklikler kaydedildi.
        </span>
        <span className="text">
          Hesabını güncelledin. Şimdi alışveriş yaparak kazanma zamanı!
        </span>
        <Button
          className="app-button"
          onClick={() => history.push(router.CAMPAIGNS)}
        >
          Fırsatlar
        </Button>
      </div>
    );
  }
  
  private renderStepContent(): ReactNode {
    if (this.props.updateMeState.data) {
      return this.renderStepSuccess();
    }
    return this.renderStepProfile();
  }
  
  render() {
    return (
      <div id="profile-page" className="page">
        <div className="page-content">
          <div className="step-content">
            {this.renderStepContent()}
          </div>
          <div className="image-wrapper">
            <div className={`image ${this.props.updateMeState.data && 'success'}`}/>
          </div>
        </div>
        {
          this.state.profileForm &&
          <ProfileApprovalModal
            isLoading={this.props.updateMeState.loading}
            callback={isApproved => this.handleApproval(isApproved)}
          />
        }
      </div>
    );
  }
  
  componentWillUnmount() {
    this.props.updateMeReset();
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      updateMe,
      updateMeReset,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    me: store.setMe.me,
    updateMeState: store.updateMe,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
