import {Button, Modal} from "antd";
import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import close from "../../../assets/images/modal-close.svg";
import icon from "../../../assets/images/modal-icon-first-time.svg";
import {MeDto} from "../../../core/models/dtos/me.dto";
import FirebaseService from "../../../core/services/firebaseService/firebase.service";
import {popupShown} from "../../../core/services/userService/popupShown/actions";
import {PopupShownState} from "../../../core/services/userService/popupShown/types";
import {IStore} from "../../../core/utilities/reducers";
import {UI} from "../../../core/utilities/ui";
import "./FirstTimeModal.scss";

interface IProps {
  me?: MeDto;
  popupShownState: PopupShownState;
  popupShown: () => void;
}

const FirstTimeModal = (props: IProps) => {
  
  useEffect(() => {
    FirebaseService.logEvent(FirebaseService.first_gain_pop_up_view);
  }, []);
  
  const isVisible = (): boolean => {
    return !!props.me?.showFirstWinPopUp;
  }
  
  const handleModal = (): void => {
    props.popupShown();
  }
  
  return (
    <Modal
      wrapClassName="first-time-modal-wrapper"
      className="first-time-modal"
      maskStyle={UI.modalMaskStyle()}
      closable
      footer={false}
      visible={isVisible()}
      centered
      destroyOnClose
      closeIcon={<img src={close} alt="close"/>}
      onCancel={handleModal}
    >
      <div className="content">
        <img className="icon" src={icon} alt="icon"/>
        <label className="title">
          Kazancın anında
          <br/>
          dijital kumbarana yüklendi!
        </label>
        <label className="text">
          İlk alışverişine özel bütün kazancın anında onaylandı. Hesabını kontrol etmeyi unutma.
        </label>
        <Button
          className="app-button"
          disabled={props.popupShownState.loading}
          onClick={handleModal}
        >
          Hesabım
        </Button>
      </div>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      popupShown,
    },
    dispatch,
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    me: store.setMe.me,
    popupShownState: store.popupShown,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstTimeModal);
