import {Dispatch} from 'redux';
import {ErrorDto} from '../../../models/dtos/error.dto';
import {OtpRequest} from '../../../models/requests/otp.request';
import {Errors} from '../../../utilities/errors';
import {history} from "../../../utilities/history";
import {LocalStorage} from "../../../utilities/localStorage";
import {router} from "../../../utilities/router";
import {SIGN_UP_OTP_FAIL, SIGN_UP_OTP_RESET, SIGN_UP_OTP_START, SIGN_UP_OTP_SUCCESS} from './constants';
import {signUpOtpRequest} from './repository';
import {SignUpOtpFailAction, SignUpOtpResetAction, SignUpOtpStartAction, SignUpOtpSuccessAction} from './types';

const signUpOtpStartAction = (): SignUpOtpStartAction => {
  return {
    type: SIGN_UP_OTP_START,
  };
};
const signUpOtpSuccessAction = (payload: string): SignUpOtpSuccessAction => {
  return {
    type: SIGN_UP_OTP_SUCCESS,
    payload: payload,
  };
};
const signUpOtpFailAction = (error: ErrorDto): SignUpOtpFailAction => {
  return {
    type: SIGN_UP_OTP_FAIL,
    error: error,
  };
};
const signUpOtpResetAction = (): SignUpOtpResetAction => {
  return {
    type: SIGN_UP_OTP_RESET,
  };
};

export const signUpOtp = (request: OtpRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(signUpOtpStartAction());
    await signUpOtpRequest(request);
    dispatch(signUpOtpSuccessAction(request.msisdn));
  } catch (error) {
    const errorDto = Errors.getErrorDtoFromApiError(error);
    if (errorDto.message === Errors.user_exists) {
      LocalStorage.set(LocalStorage.msisdn, request.msisdn);
      history.push(router.LOGIN);
    }
    dispatch(signUpOtpFailAction(Errors.getErrorDtoFromApiError(error)));
  }
};

export const signUpOtpReset = () => (dispatch: Dispatch) => {
  dispatch(signUpOtpResetAction());
};
