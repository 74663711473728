export class Constants {
  public static readonly dateFormatUI: string = 'DD.MM.yyyy';
  public static readonly dateFormatDB: string = 'yyyy-MM-DD';
  public static readonly dateTimeFormatUI: string = 'DD-MM-yyyy HH:mm:ss';
  
  public static readonly otpLength: number = 6;
  
  public static readonly inputErrorRequiredField = 'Bu alan boş olamaz';
  public static readonly inputErrorFileUpload = 'Dosya yüklenirken bir hata oluştu.';
  
  public static readonly link4P: string = 'https://www.4play.com.tr';
  public static readonly linkAppStore: string = 'https://bit.ly/metabytekazan';
  public static readonly linkGooglePlay: string = 'https://bit.ly/metabytekazan';
  public static readonly linkWallet: string = 'https://links.kimgbister.com/bireysel-landing-button';
  
  public static readonly linkCookieDetails: string = 'https://static.4play.com.tr/cashback/docs/cerez-politikasi.pdf';
  public static readonly linkUserAgreement: string = 'https://static.4play.com.tr/cashback/docs/kullanıcı-sozlesmesi.pdf';
  public static readonly linkETK: string = 'https://static.4play.com.tr/cashback/docs/ticari-elektronik-ileti-onayi.pdf';
}
