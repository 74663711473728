import axios from '../../../utilities/axios';
import {PointDto} from '../../../models/dtos/point.dto';

export const getPointsRequest = async (): Promise<PointDto[]> => {
  try {
    const response = await axios.get<PointDto[]>(
      `${process.env.REACT_APP_BASE_URL}/user/me/point`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
