import {Breadcrumb} from "antd";
import React from "react";
import separator from "../../../../assets/images/breadcrumb-separator.svg";
import "./SignUpBreadcrumb.scss";

interface IProps {
  step: SignUpStep;
}

export enum SignUpStep {
  signup,
  otp,
  complete,
}

const SignUpBreadcrumb = (props: IProps) => {

  function isStepActive(item: SignUpStep): boolean {
    return item === props.step;
  }

  function getItemClass(item: SignUpStep): string {
    return `item ${isStepActive(item) && 'active'}`;
  }

  return (
    <Breadcrumb
      className="sign-up-breadcrumb"
      separator={<img src={separator} alt="arrow"/>}
    >
      <Breadcrumb.Item className={getItemClass(SignUpStep.signup)}>Telefon Numaranı Gir</Breadcrumb.Item>
      <Breadcrumb.Item className={getItemClass(SignUpStep.otp)}>Doğrula</Breadcrumb.Item>
      <Breadcrumb.Item className={getItemClass(SignUpStep.complete)}>Hazırsın!</Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default SignUpBreadcrumb;
