import {Dispatch} from 'redux';
import {ErrorDto} from '../../../models/dtos/error.dto';
import {MeDto} from '../../../models/dtos/me.dto';
import {Errors} from '../../../utilities/errors';
import {GET_ME_FAIL, GET_ME_RESET, GET_ME_START, GET_ME_SUCCESS} from './constants';
import {getMeRequest} from './repository';
import {GetMeFailAction, GetMeResetAction, GetMeStartAction, GetMeSuccessAction} from './types';

const getMeStartAction = (): GetMeStartAction => {
  return {
    type: GET_ME_START,
  };
};
const getMeSuccessAction = (payload: MeDto): GetMeSuccessAction => {
  return {
    type: GET_ME_SUCCESS,
    payload: payload,
  };
};
const getMeFailAction = (error: ErrorDto): GetMeFailAction => {
  return {
    type: GET_ME_FAIL,
    error: error,
  };
};
const getMeResetAction = (): GetMeResetAction => {
  return {
    type: GET_ME_RESET,
  };
};

export const getMe = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getMeStartAction());
    const response = await getMeRequest(dispatch);
    dispatch(getMeSuccessAction(response));
  } catch (error) {
    dispatch(getMeFailAction(Errors.getErrorDtoFromApiError(error)));
  }
};

export const getMeReset = () => (dispatch: Dispatch) => {
  dispatch(getMeResetAction());
};
