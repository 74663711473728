export class LocalStorage {
  public static readonly token: string = 'token';
  public static readonly cookieAgreement: string = 'cookieAgreement';
  public static readonly profileWarning: string = 'profileWarning';
  public static readonly notificationIds: string = 'notificationIds';
  public static readonly redirect: string = 'redirect';
  public static readonly msisdn: string = 'msisdn';
  
  public static get(key: string) {
    return localStorage.getItem(key);
  }
  
  public static set(key: string, value: string) {
    return localStorage.setItem(key, value);
  }
  
  public static remove(key: string) {
    return localStorage.removeItem(key);
  }
  
  public static addSeenNotificationIds(ids: number[]): void {
    const currentList = JSON.parse(this.get(this.notificationIds) ?? '[]') as number[];
    const updatedList = [...currentList, ...ids];
    this.set(this.notificationIds, JSON.stringify(updatedList));
  }
}
