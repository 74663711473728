import React from "react";
import warning from "../../assets/images/warning.svg";
import "./SafariWarning.scss";

interface IProps {
}

const SafariWarning = (props: IProps) => {
  
  return (
    <div id="safari-warning">
      <img className="icon" src={warning} alt="warning"/>
      <div className="warning-content">
        <span className="title">
          Lütfen tarayıcını değiştir!
        </span>
        <span className="text">
          MetaByte Kazan’dan en iyi şekilde yararlanabilmen için çerezler kullanıyoruz.
          Safari tarayıcısı otomatik olarak çerezleri engellediği için alışverişlerine farklı bir tarayıcı ile devam et.
        </span>
      </div>
    </div>
  );
}

export default SafariWarning;
