import {Checkbox, InputNumber} from "antd";
import React, {ReactNode, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import rightPrimary from "../../../../assets/images/chevron-right-primary.svg";
import {CampaignFilterDto, CampaignFilterItemDto} from "../../../../core/models/custom/campaignFilter.dto";
import {setCampaignFilter} from "../../../../core/services/appService/setCampaignFilter/actions";
import {SetCampaignFilterState} from "../../../../core/services/appService/setCampaignFilter/types";
import {Helpers} from "../../../../core/utilities/helpers";
import {IStore} from "../../../../core/utilities/reducers";
import "./CampaignFilter.scss";

interface IProps {
  callbackClearFilter: () => void;
  setCampaignFilterState: SetCampaignFilterState;
  setCampaignFilter: (filter: CampaignFilterDto) => void;
}

enum ItemType {
  brand = 'brand',
  category = 'category',
}

enum RatioType {
  min = 'min',
  max = 'max',
}

const CampaignFilter = (props: IProps) => {
  const [isCategoriesExtended, setIsCategoriesExtended] = useState(false);
  const [isBrandsExtended, setIsBrandsExtended] = useState(false);
  
  function updateItems(items: CampaignFilterItemDto[], item: CampaignFilterItemDto): CampaignFilterItemDto[] {
    return items.map(i => {
      if (i.name === item.name) {
        i.checked = !i.checked;
      }
      return i;
    });
  }
  
  function handleClickFilterItem(item: CampaignFilterItemDto, type: ItemType): void {
    const filter = props.setCampaignFilterState.data;
    let updatedItems: CampaignFilterItemDto[] = [];
    switch (type) {
      case ItemType.brand:
        updatedItems = updateItems(filter.brands, item);
        break;
      case ItemType.category:
        updatedItems = updateItems(filter.categories, item);
        break;
    }
    props.setCampaignFilter({
      ...filter,
      brands: type === ItemType.brand ? updatedItems : filter.brands,
      categories: type === ItemType.category ? updatedItems : filter.categories,
    });
  }
  
  function handleChangeRatio(ratio: number, type: RatioType): void {
    const filter = props.setCampaignFilterState.data;
    props.setCampaignFilter({
      ...filter,
      ratios: {
        min: type === RatioType.min ? ratio : filter.ratios.min,
        max: type === RatioType.max ? ratio : filter.ratios.max,
      }
    });
  }
  
  function renderFilterItem(item: CampaignFilterItemDto, type: ItemType): ReactNode {
    const items = type === ItemType.brand
      ? props.setCampaignFilterState.data.brands
      : props.setCampaignFilterState.data.categories;
    const checked = items.find(i => i.name === item.name)?.checked;
    return (
      <div
        key={`${type}-${item.name}`}
        className="filter-item"
        onClick={() => handleClickFilterItem(item, type)}
      >
        <span className="name">{item.name}</span>
        <Checkbox className="checkbox" checked={checked}/>
      </div>
    );
  }
  
  function renderMore(callback: () => void): ReactNode {
    return (
      <div className="more" onClick={callback}>
        <span>Daha fazla</span>
        <img className="arrow" src={rightPrimary} alt="arrow"/>
      </div>
    );
  }
  
  function renderCategories(): ReactNode {
    let categories = props.setCampaignFilterState.data.categories;
    if (!isCategoriesExtended) {
      categories = categories.slice(0, 2);
    }
    return (
      <>
        {categories.map(item => renderFilterItem(item, ItemType.category))}
        {
          !isCategoriesExtended &&
          renderMore(() => setIsCategoriesExtended(true))
        }
      </>
    );
  }
  
  function renderBrands(): ReactNode {
    let brands = props.setCampaignFilterState.data.brands;
    if (!isBrandsExtended) {
      brands = brands.slice(0, 2);
    }
    return (
      <>
        {brands.map(item => renderFilterItem(item, ItemType.brand))}
        {
          !isBrandsExtended &&
          renderMore(() => setIsBrandsExtended(true))
        }
      </>
    );
  }
  
  return (
    <div id="campaign-filter">
      <section className="filter-section">
        <p className="filter-title">Kategoriler</p>
        <div className="filter-items">
          {renderCategories()}
        </div>
      </section>
      
      <section className="filter-section brands">
        <p className="filter-title">Markalar</p>
        <div className="filter-items">
          {renderBrands()}
        </div>
      </section>
      
      <section className="filter-section ratios">
        <p className="filter-title">Kazanç Oranı</p>
        <div className="ratios-wrapper">
          <div className="ratio-wrapper">
            <span className="name">Min</span>
            <InputNumber
              className="ratio" value={props.setCampaignFilterState.data.ratios.min}
              type="number" min={0} max={100} placeholder="0"
              onChange={e => handleChangeRatio(e, RatioType.min)}
            />
          </div>
          <span className="dash">-</span>
          <div className="ratio-wrapper">
            <span className="name">Max</span>
            <InputNumber
              className="ratio" value={props.setCampaignFilterState.data.ratios.max}
              type="number" min={0} max={100} placeholder="100"
              onChange={e => handleChangeRatio(e, RatioType.max)}
            />
          </div>
        </div>
      </section>
      
      {
        Helpers.isCampaignFilterActive(props.setCampaignFilterState.data) &&
        <span
          className="btn-clear"
          onClick={props.callbackClearFilter}
        >
          Temizle
        </span>
      }
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setCampaignFilter,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    setCampaignFilterState: store.setCampaignFilter,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignFilter);
