import {Button, Checkbox, Form, Input} from "antd";
import React, {useState} from 'react';
import {FormError} from "../../../../core/models/custom/formError";
import {FormValuesSignUp} from "../../../../core/models/custom/formValuesSignUp";
import FirebaseService from "../../../../core/services/firebaseService/firebase.service";
import {Constants} from "../../../../core/utilities/constants";
import "./SignUpForm.scss";

interface IProps {
  msisdnInitial: string;
  isLoading: boolean;
  callbackSubmit: (values: FormValuesSignUp) => void;
}

const SignUpForm = (props: IProps) => {
  const [isContractApproved, setContractApproved] = useState(false);
  const [isEtkApproved, setEtkApproved] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isMsisdnError, setMsisdnError] = useState(false);
  const [form] = Form.useForm();
  
  function handleMsisdnChange(msisdn: string): void {
    if (msisdn) {
      if (msisdn.substring(0, 1) !== '5') {
        form.setFieldsValue({msisdn: ''});
      } else if (msisdn.length > 10) {
        form.setFieldsValue({msisdn: msisdn.substring(0, 10)});
        setMsisdnError(false);
      } else if (isSubmitted) {
        setMsisdnError(msisdn.length !== 10);
      }
    }
  }
  
  function handleMsisdnKeyPress(e: React.KeyboardEvent): void {
    if (isNaN(parseInt(e.key))) {
      e.preventDefault();
    }
  }
  
  function handleFail(e: any): void {
    setSubmitted(true);
    setMsisdnError((e as FormError).errorFields.filter(err => err.name.includes('msisdn')).length > 0);
  }
  
  function handleFinish(values: FormValuesSignUp): void {
    FirebaseService.logEvent(FirebaseService.sign_up_continue_clicked);
    props.callbackSubmit({
      ...values,
      msisdn: `90${values.msisdn}`,
    });
  }
  
  return (
    <Form
      id="sign-up-form"
      form={form}
      initialValues={{
        msisdn: props.msisdnInitial
      }}
      onFinishFailed={handleFail}
      onFinish={handleFinish}
    >
      <div className={`app-input ${isMsisdnError && 'error'}`}>
        <span className="prefix">+90</span>
        <div className="input-wrapper">
          <span className="label">Telefon Numaran</span>
          <Form.Item
            name="msisdn"
            className="input"
            rules={[
              {required: true, message: ''},
              {len: 10, message: ''},
            ]}
          >
            <Input
              key="signup"
              type="tel"
              placeholder="5xxxxxxxxx"
              onChange={e => handleMsisdnChange(e.target.value)}
              onKeyPress={handleMsisdnKeyPress}
            />
          </Form.Item>
        </div>
      </div>
      
      {
        isMsisdnError &&
        <span className="info">
          Lütfen telefon numaranı kontrol ederek tekrar gir.
        </span>
      }
      
      <div className="checkboxes">
        <Form.Item
          name="contractApproved"
          valuePropName="checked"
          className="checkbox"
          rules={[{
            required: true,
            transform: value => (value || undefined),
            type: 'boolean',
            message: '',
          }]}
        >
          <Checkbox
            checked={isContractApproved}
            onChange={e => setContractApproved(e.target.checked)}
          >
            <a
              href={Constants.linkUserAgreement}
              className="checkbox-text"
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => form.setFieldsValue({contractApproved: true})}
            >
              Kullanıcı Sözleşmesi'ni okudum ve onaylıyorum.
            </a>
          </Checkbox>
        </Form.Item>
        
        <Form.Item
          name="etkApproved"
          valuePropName="checked"
          className="checkbox"
        >
          <Checkbox
            checked={isEtkApproved}
            onChange={e => setEtkApproved(e.target.checked)}
          >
            <a
              href={Constants.linkETK}
              className="checkbox-text"
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => form.setFieldsValue({etkApproved: true})}
            >
              Yeni fırsatlardan haberdar olabilmek için Ticari Elektronik İleti Onayı'nı veriyorum.
            </a>
          </Checkbox>
        </Form.Item>
      </div>
      
      <Button
        htmlType="submit"
        className="app-button"
        disabled={props.isLoading}
      >
        Devam Et
      </Button>
    </Form>
  );
}

export default SignUpForm;
