import {Dispatch} from 'redux';
import {ErrorDto} from '../../../models/dtos/error.dto';
import {TokenDto} from '../../../models/dtos/token.dto';
import {SignUpRequest} from "../../../models/requests/signUp.request";
import {Errors} from '../../../utilities/errors';
import {history} from "../../../utilities/history";
import {LocalStorage} from "../../../utilities/localStorage";
import {router} from "../../../utilities/router";
import FirebaseService from "../../firebaseService/firebase.service";
import {SIGN_UP_FAIL, SIGN_UP_RESET, SIGN_UP_START, SIGN_UP_SUCCESS} from './constants';
import {signUpRequest} from './repository';
import {SignUpFailAction, SignUpResetAction, SignUpStartAction, SignUpSuccessAction} from './types';

const signUpStartAction = (): SignUpStartAction => {
  return {
    type: SIGN_UP_START,
  };
};
const signUpSuccessAction = (payload: TokenDto): SignUpSuccessAction => {
  return {
    type: SIGN_UP_SUCCESS,
    payload: payload,
  };
};
const signUpFailAction = (error: ErrorDto): SignUpFailAction => {
  return {
    type: SIGN_UP_FAIL,
    error: error,
  };
};
const signUpResetAction = (): SignUpResetAction => {
  return {
    type: SIGN_UP_RESET,
  };
};

export const signUp = (request: SignUpRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(signUpStartAction());
    const response = await signUpRequest(request);
    LocalStorage.set(LocalStorage.token, response.token);
    // await getMeRequest(dispatch); me request will be triggered from page
    dispatch(signUpSuccessAction(response));
    FirebaseService.logEvent(FirebaseService.sign_up_success_view);
  } catch (error) {
    const errorDto = Errors.getErrorDtoFromApiError(error);
    if (errorDto.message === Errors.user_exists) {
      history.push(router.LOGIN);
    }
    dispatch(signUpFailAction(errorDto));
  }
};

export const signUpReset = () => (dispatch: Dispatch) => {
  dispatch(signUpResetAction());
};
