import React from 'react';
import poly1 from "../../../../../assets/images/poly-1.svg";
import poly2 from "../../../../../assets/images/poly-2.svg";
import poly3 from "../../../../../assets/images/poly-3.svg";
import poly4 from "../../../../../assets/images/poly-4.svg";
import {IWorkCard} from "../SectionHowItWorks";
import "./WorkCard.scss";

interface IProps {
  workCard: IWorkCard;
}

const WorkCard = (props: IProps) => {

  function getStepImage(): string {
    switch (props.workCard.step) {
      case 1:
        return poly1;
      case 2:
        return poly2;
      case 3:
        return poly3;
      case 4:
        return poly4;
      default:
        return '';
    }
  }

  return (
    <div id="work-card">
      <img className="step" src={getStepImage()} alt="step"/>
      <div className="image">{props.workCard.image}</div>
      <p className="text">{props.workCard.text}</p>
    </div>
  );
}

export default WorkCard;
