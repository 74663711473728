import React, {Component} from "react";
import FirebaseService from "../../../core/services/firebaseService/firebase.service";
import CalculatorForm from "./CalculatorForm/CalculatorForm";
import "./CalculatorPage.scss";

interface IProps {
}

class CalculatorPage extends Component<IProps> {

  componentDidMount() {
    FirebaseService.logEvent(FirebaseService.calculator_view);
  }

  render() {
    return (
      <div id="calculator-page" className="page">
        <div className="page-content">
          <div className="step-content">
            <CalculatorForm/>
          </div>
          <div className="image-wrapper">
            <div className="image"/>
          </div>
        </div>
      </div>
    );
  }
}

export default CalculatorPage;
