import React, { ReactNode } from "react";
import { Form, Input, Select, Button, Upload } from "antd";
import UploadLogo from "../../../assets/images/add.svg";
import UploadLogoDark from "../../../assets/images/add-dark.svg";
import { CheckOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../../core/utilities/reducers";
import { ContactRequest } from "../../../core/models/requests/contact.request";
import FirebaseService from "../../../core/services/firebaseService/firebase.service";
import {contact, contactReset} from '../../../core/services/contactService/contact/actions';
import {ContactState} from '../../../core/services/contactService/contact/types';
import MiscService from "../../../core/services/miscService/misc.service";
import customerFeedback from "../../../assets/images/customer-feedback.svg";
import "./ContactUsPage.scss";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { LocalStorage } from "../../../core/utilities/localStorage";
import {LoadingOutlined} from '@ant-design/icons';
import axios from "../../../core/utilities/axios";
import { history } from "../../../core/utilities/history";
import { router } from "../../../core/utilities/router";
import { FormError } from "../../../core/models/custom/formError";
import { MeDto } from "../../../core/models/dtos/me.dto";



interface IProps {
  me?: MeDto;
  contact: (request: ContactRequest) => void;
  contactState: ContactState;
  contactReset: () => void;
}

const {Option} = Select;

interface State {
  fileUrl?: string;
  subject: string[];
  subjects: string;
  email: string;
  nameSurname: string;
  body: string;
  msisdn: string;
  isNameError: false;
  isMsisdnError: false;
  isMailError: false;
  isSubjectError: false;
  isMessageError: false;
  isSubmitted: boolean;
  isFormValid: boolean;
  isUploading: boolean;
}

class ContactUsPage extends React.Component<IProps> {
  state: State = {
    fileUrl: undefined,
    isNameError: false,
    isMsisdnError: false,
    isMailError: false,
    isSubjectError: false,
    isMessageError: false,
    isSubmitted: false,
    isFormValid: false,
    isUploading: false,
    subject: [],
    email: '',
    nameSurname: '',
    body: '',
    msisdn: '',
    subjects: '',
  }

  private miscService: MiscService = new MiscService();

  componentDidMount() {
    FirebaseService.logEvent(FirebaseService.contact_us_view);
    const subject: string[] = FirebaseService.getContactSubjects();
    this.setState({subject});
  }

  private handleMsisdnKeyPress(e: React.KeyboardEvent): void {
    if (isNaN(parseInt(e.key))) {
      e.preventDefault();
    }
  }

  private handleFinish = async (request: ContactRequest): Promise<void> => {
    this.setState({isSubmitted: true})
    if (this.state.fileUrl) {
      request.file = this.state.fileUrl;
    }
    this.props.contact(request)
    setTimeout(() => {
      history.push(router.LANDING)
    }, 1800)

  }


  private handleNameChange(e: any): void {
    const isNameOk = this.state.nameSurname.length > 0;

    isNameOk
    ? this.setState({isNameError: true})
    : this.setState({isNameError: false});
  }

  private handleMsisdnChange(e: any): void {
    const isMsisdnOk = this.state.msisdn.length === 10 && this.state.msisdn.substring(0, 1) !== '5' ;

    isMsisdnOk
    ? this.setState({isMsisdnError: true})
    : this.setState({isMsisdnError: false});
  }

  private handleMailChange(e: any): void {
    const isMailOk = this.state.email.length > 0;

    isMailOk
    ? this.setState({isMailError: true})
    : this.setState({isMailError: false});
  }

  private handleSubjectChange(e: any): void {
    const isSubjectOk = this.state.subjects.length > 0;

    isSubjectOk
    ? this.setState({isSubjectError: true})
    : this.setState({isSubjectError: false});
  }

  private handleBodyChange(e: any): void {
    const isBodyOk = this.state.body.length > 0;

    isBodyOk
    ? this.setState({isMessageError: true})
    : this.setState({isMessageError: false});
  }

  private handleIfFormValid(): boolean {
    const formValid : boolean = !this.state.isNameError && !this.state.isMsisdnError && !this.state.isMailError && !this.state.isSubjectError && !this.state.isMessageError

    return formValid;
  }

  private handleFail(e: any): void {
    this.setState({isSubmitted: false})
    const isMsisdnOk = ((e as FormError).errorFields.filter((err) => err.name.includes("msisdn")).length > 0);
    const isNameOk = ((e as FormError).errorFields.filter((err) => err.name.includes("nameSurname")).length > 0);
    const isMailOk = ((e as FormError).errorFields.filter((err) => err.name.includes("email")).length > 0);
    const isSubjectOk = ((e as FormError).errorFields.filter((err) => err.name.includes("subject")).length > 0);
    const isMessageOk = ((e as FormError).errorFields.filter((err) => err.name.includes("body")).length > 0);

    isMsisdnOk
      ? this.setState({isMsisdnError: true})
      : this.setState({isMsisdnError: false});

    isNameOk
      ? this.setState({isNameError: true})
      : this.setState({isNameError: false});

    isMailOk
      ? this.setState({isMailError: true})
      : this.setState({isMailError: false});

    isSubjectOk
      ? this.setState({isSubjectError: true})
      : this.setState({isSubjectError: false});

    isMessageOk
      ? this.setState({isMessageError: true})
      : this.setState({isMessageError: false});
  }

  uploadFile = async (options: any): Promise<void> => {
    this.setState({isUploading: true});
    const {file} = options;
    const formData = new FormData();
    const config = {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${LocalStorage.get(LocalStorage.token)}`,
      },
    };
    formData.append('file', file);
    const response = await axios.post(
      `https://autologin.solidict.com/upload/image`,
      formData,
      config,

    );
    this.setState({fileUrl : response.data.url})
    this.setState({isUploading: false});
  }

  private normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  private renderForm(): ReactNode {
    return (
      <Form
      id="contact-us-form"
      className="form"
      onFinish={this.handleFinish}
      onFinishFailed={(e) => this.handleFail(e)}
      initialValues={{
        nameSurname: this.props.me?.nameSurname,
        msisdn: this.props.me?.msisdn,
        email: this.props.me?.email,
      }}
    >
      <PageTitle className="title" text="İletişim Bilgileri" />

      <div className="contact-section">

      <div className="item-wrapper one" >
          <div className={`app-input ${this.state.isNameError && "error"}`}>
            <div className="input-wrapper">
              <Form.Item
                name="nameSurname"
                className="input"
                rules={[{ required: true, message: "" }]}
              >
                <Input className="input-text" placeholder="Adın Soyadın" onChange={(e) => this.handleNameChange(e)} value={this.state.nameSurname}  />
              </Form.Item>
            </div>
          </div>
          <span className={`info ${this.state.isNameError && "red"}`}>*</span>
        </div>

        <div className="item-wrapper two">
          <div className={`app-input ${this.state.isMsisdnError && "error"}`}>
            <span className="prefix">+90</span>
            <span className="square"></span>
            <div className="input-wrapper">
              <span className="label">Telefon Numaran</span>
              <Form.Item
                name="msisdn"
                className="input"
                rules={[
                  { required: true, message: "" },
                  { len: 10, message: "" },
                ]}
              >
                <Input
                  className="input-text"
                  type="tel"
                  maxLength={10}
                  placeholder="5xxxxxxxxx"
                  onKeyPress={this.handleMsisdnKeyPress}
                  onChange={(e) => this.handleMsisdnChange(e)}
                  value={this.state.msisdn}
                />
              </Form.Item>
            </div>
          </div>
          <span className={`info ${this.state.isMsisdnError && "red"}`}>*</span>
        </div>

        <div className="item-wrapper three">
          <div className={`app-input ${this.state.isMailError && "error"} mail`}>
            <div className="input-wrapper">
              <span className="label">E-Mail</span>
              <Form.Item
                className="input "
                name="email"
                rules={[
                  { required: true, message: "" },
                  { type: "email", message: "" },
                ]}
              >
                <Input className="input-text"
                type="email"
                placeholder="E-Mail Adresin"
                onChange={(e) => this.handleMailChange(e)}
                value={this.state.email}
                />
              </Form.Item>
            </div>
          </div>
          <span className={`info ${this.state.isMailError && "red"}`}>*</span>
        </div>

      </div>

      <PageTitle className="title" text="Mesajın" />

      <div className="issue-section">
        <div className="item-wrapper">
          <div className={`app-input ${this.state.isSubjectError && "error"} long`}>
            <div className="input-wrapper">
              <Form.Item
                name="subject"
                className="input"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  className="sorting"
                  dropdownClassName="sorting-dropdown"
                  placeholder="Konu"
                  onChange={(e) => this.handleSubjectChange(e)}
                  value={this.state.subjects}
                >
                  {
                this.state.subject.map((subject) => {
                  return (
                    <Option
                      key={subject}
                      value={subject}
                    >
                      {subject}
                    </Option>
                  );
                })
                  }
                </Select>
              </Form.Item>
            </div>
          </div>
          <span className={`info ${this.state.isSubjectError && "red"}`}>*</span>
        </div>

        <div className="item-wrapper">
          <div
            className={`app-input ${this.state.isMessageError && "error"} long textarea`}
          >
            <div className="input-wrapper">
              <span className="label">Mesaj</span>
              <Form.Item
                name="body"
                className="input"
                rules={[{ required: true, message: "" }]}
              >
                <Input.TextArea
                  className="input-text"
                  autoFocus
                  autoSize
                  placeholder="Mesaj"
                  rows={2}
                  onChange={(e) => this.handleBodyChange(e)}
                  value={this.state.body}
                />
              </Form.Item>
            </div>
          </div>
          <span className={`info ${this.state.isMessageError && "red"}`}>*</span>
        </div>
      </div>

      <div className="button-wrapper">

      <Form.Item
        name="file"
        valuePropName="fileList"
        getValueFromEvent={this.normFile}
      >
        <Upload
          name="file"
          customRequest={(options)=> this.uploadFile(options)}
          showUploadList={false}
        >
          <Button className={`upload-button ${this.state.isSubmitted && this.handleIfFormValid() ? 'success' : '' } `} disabled={this.state.isUploading}>
            {
              this.state.isUploading ? (<LoadingOutlined/>) :
              this.state.isSubmitted && this.handleIfFormValid() ?
              <img src={UploadLogoDark} alt="upload" /> :
              <img src={UploadLogo} alt="upload" />
            }

          </Button>
        </Upload>
      </Form.Item>

      <Form.Item>
      <Button
      htmlType="submit"
      className="app-button"
      disabled={this.state.isUploading}
      onClick={() => this.setState({isSubmitted:true})}
      >
        {
          this.state.isSubmitted && this.handleIfFormValid()
            ? <span>Gönderildi <CheckOutlined /></span>
            : "Gönder"
        }
      </Button>
      </Form.Item>
      </div>
    </Form>
    );
  }

  render() {
    return (
      <div id="contact-us-page" className="page">
        <div className="page-content">
          <div className="form-content">
           {this.renderForm()}
          </div>
          <div className="image-wrapper">
            <div
              className="image"
              style={{
                background: `url("${customerFeedback}") no-repeat center`,
                backgroundSize: "cover",
              }}
            />
          </div>

          </div>
        </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    contact,
    contactReset,
  }, dispatch);
};
const mapStateToProps = (store: IStore) => {
  return {
    contactState: store.contact,
    me: store.setMe.me,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);

