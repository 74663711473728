import {Carousel} from "antd";
import {CarouselRef} from "antd/es/carousel";
import React, {ReactNode} from 'react';
import cosmetic from "../../../../../assets/images/banner-cosmetic.png";
import digital from "../../../../../assets/images/banner-digital.png";
import electronic from "../../../../../assets/images/banner-electronic.png";
import entertainment from "../../../../../assets/images/banner-entertainment.png";
import fashion from "../../../../../assets/images/banner-fashion.png";
import initial from "../../../../../assets/images/banner-initial.png";
import lifestyle from "../../../../../assets/images/banner-lifestyle.png";
import mom from "../../../../../assets/images/banner-mom.png";
import sport from "../../../../../assets/images/banner-sport.png";
import carouselNext from "../../../../../assets/images/carousel-next.svg";
import carouselPrev from "../../../../../assets/images/carousel-prev.svg";
import "./BannerCarousel.scss";

interface IProps {
}

const BannerCarousel = (props: IProps) => {
  const carouselRef = React.createRef<CarouselRef>()
  
  function handleNavigation(isNext: boolean): void {
    isNext ? carouselRef.current?.next() : carouselRef.current?.prev();
  }
  
  function renderCarouselItem(image: string): ReactNode {
    return (
      <div className="carousel-item">
        <img className="image" src={image} alt="banner"/>
      </div>
    );
  }
  
  return (
    <div id="banner-carousel">
      <Carousel
        ref={carouselRef}
        dots={false}
        draggable
        autoplay
        autoplaySpeed={2500}
      >
        {renderCarouselItem(initial)}
        {renderCarouselItem(electronic)}
        {renderCarouselItem(fashion)}
        {renderCarouselItem(cosmetic)}
        {renderCarouselItem(lifestyle)}
        {renderCarouselItem(sport)}
        {renderCarouselItem(mom)}
        {renderCarouselItem(entertainment)}
        {renderCarouselItem(digital)}
      </Carousel>
      <div className="controls">
        <img
          className="control" src={carouselPrev} alt="prev"
          onClick={() => handleNavigation(false)}
        />
        <img
          className="control" src={carouselNext} alt="next"
          onClick={() => handleNavigation(true)}
        />
      </div>
    </div>
  );
}

export default BannerCarousel;
