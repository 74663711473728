import {Button, Menu, Switch} from "antd";
import React, {ReactNode, useState} from 'react';
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {bindActionCreators, Dispatch} from "redux";
import drawerCalculator from "../../assets/images/drawer-calculator.svg";
import drawerCookies from "../../assets/images/drawer-cookies.svg";
import drawerHeadphones from "../../assets/images/drawer-headphones.svg";
import drawerHelp from "../../assets/images/drawer-help.svg";
import drawerNotificationsActive from "../../assets/images/drawer-notifications-active.svg";
import drawerNotifications from "../../assets/images/drawer-notifications.svg";
import drawerQuestionMark from "../../assets/images/drawer-question-mark.svg";
import logoMobile from "../../assets/images/logo-mobile.svg";
import logo from "../../assets/images/logo.svg";
import campaigns from "../../assets/images/navbar-campaigns.svg";
import drawerActive from "../../assets/images/navbar-drawer-active.svg";
import drawer from "../../assets/images/navbar-drawer.svg";
import profile from "../../assets/images/navbar-profile.svg";
import {MeDto} from "../../core/models/dtos/me.dto";
import {setMe} from "../../core/services/appService/setMe/actions";
import {SetUnreadNotificationIdsState} from "../../core/services/appService/setUnreadNotificationIds/types";
import FirebaseService from "../../core/services/firebaseService/firebase.service";
import {Helpers} from "../../core/utilities/helpers";
import {history} from "../../core/utilities/history";
import {IStore} from "../../core/utilities/reducers";
import {router} from "../../core/utilities/router";
import LogoutModal from "../Modals/LogoutModal/LogoutModal";
import "./AppHeader.scss";

const {SubMenu} = Menu;

interface IProps {
  pathname: string;
  me?: MeDto;
  setMe: () => void;
  setUnreadNotificationIdsState: SetUnreadNotificationIdsState;
}

const AppHeader = (props: IProps) => {
  const [isLogoutModalVisible, setLogoutModalVisible] = useState(false);
  
  function isNewNotification(): boolean {
    return !!props.setUnreadNotificationIdsState.data && props.setUnreadNotificationIdsState.data.length > 0;
  }
  
  function handleCookieChange(value: boolean) {
    if (!value) {
      FirebaseService.logEvent(FirebaseService.cookie_switch_off);
      Helpers.deleteAllCookies();
    } else {
      FirebaseService.logEvent(FirebaseService.cookie_switch_on);
    }
  }
  
  function handleLogoutModal(isApproved: boolean) {
    setLogoutModalVisible(false);
    if (isApproved) {
      props.setMe();
    }
  }
  
  function getMediaQueryClass(isMobile: boolean): string {
    return isMobile ? 'd-inline d-md-none' : 'd-none d-md-inline';
  }
  
  function renderLogo(): ReactNode {
    return (
      <Menu.Item
        key="logo"
        className="menu-item"
        icon={
          <React.Fragment>
            <img
              className={getMediaQueryClass(true)}
              src={logoMobile}
              alt="logo"
              onClick={() => history.push(router.LANDING)}
            />
            <img
              className={getMediaQueryClass(false)}
              src={logo}
              alt="logo"
              onClick={() => history.push(router.LANDING)}
            />
          </React.Fragment>
        }
      />
    );
  }
  
  function renderCampaigns(): ReactNode {
    return (
      <Menu.Item
        key="campaigns"
        className="menu-item"
      >
        <React.Fragment>
          <NavLink
            to={router.CAMPAIGNS}
            className={getMediaQueryClass(true)}
            onClick={() => FirebaseService.logEvent(FirebaseService.campaigns_clicked)}
          >
            <img className="icon" src={campaigns} alt="campaigns"/>
          </NavLink>
          <NavLink
            to={router.CAMPAIGNS}
            className={getMediaQueryClass(false)}
            onClick={() => FirebaseService.logEvent(FirebaseService.campaigns_clicked)}
          >
            Fırsatlar
          </NavLink>
        </React.Fragment>
      </Menu.Item>
    );
  }
  
  function renderProfile(): ReactNode {
    return (
      !!props.me
        ?
        <Menu.Item
          key="profile"
          className="menu-item"
        >
          <NavLink
            to={router.ACCOUNT}
            onClick={() => FirebaseService.logEvent(FirebaseService.my_account_clicked)}
          >
            <img className={`${getMediaQueryClass(true)} icon`} src={profile} alt="profile"/>
            <img className={`${getMediaQueryClass(false)}`} src={profile} alt="profile"/>
          </NavLink>
        </Menu.Item>
        :
        <Menu.Item
          key="login"
          className="menu-item"
        >
          <Button className="app-button">
            <NavLink
              to={router.LOGIN}
              onClick={() => FirebaseService.logEvent(FirebaseService.login_clicked)}
            >
              Giriş
            </NavLink>
          </Button>
        </Menu.Item>
    );
  }
  
  function renderDrawer(): ReactNode {
    return (
      <SubMenu
        key="drawer"
        className="drawer"
        title={<img src={isNewNotification() ? drawerActive : drawer} alt="drawer"/>}
        popupClassName="drawer-popup"
      >
        <Menu.Item key="notifications">
          <NavLink to={router.NOTIFICATIONS}>
            <img
              src={isNewNotification() ? drawerNotificationsActive : drawerNotifications}
              alt="notifications"/>
            <span className="text">Bildirimler</span>
            {
              isNewNotification() &&
              <span className="notification-count">{props.setUnreadNotificationIdsState.data?.length}</span>
            }
          </NavLink>
        </Menu.Item>
        <Menu.Item key="calculator">
          <NavLink to={router.CALCULATOR} className="calculator">
            <img src={drawerCalculator} alt="calculator"/>
            <span className="text">Ne Kadar Kazanırım?</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="help">
          <NavLink
            to={router.HOW_IT_WORKS}
            onClick={() => FirebaseService.logEvent(FirebaseService.how_it_works_hamburger_clicked)}
          >
            <img src={drawerQuestionMark} alt="help"/>
            <span className="text">Nasıl Kazanırım?</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="question">
          <NavLink to={router.FAQ}>
            <img src={drawerHelp} alt="question"/>
            <span className="text">Yardım</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="contact">
          <NavLink to={router.CONTACT_US}>
            <img src={drawerHeadphones} alt="contact"/>
            <span className="text">Bize Ulaş</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="cookies">
          <div className="d-flex align-items-center" onClick={e => e.stopPropagation()}>
            <img src={drawerCookies} alt="cookies"/>
            <span className="text">Çerezler</span>
            <Switch
              className="switch"
              defaultChecked
              onChange={e => handleCookieChange(e.valueOf())}
            />
          </div>
        </Menu.Item>
        {
          !!props.me &&
          <Menu.Item
            key="menu-item-4" className="logout"
            onClick={() => setLogoutModalVisible(true)}
          >
            <span className="text">Çıkış</span>
          </Menu.Item>
        }
      </SubMenu>
    );
  }
  
  function renderPageTitle(): ReactNode {
    let title = '';
    switch (props.pathname) {
      case router.LOGIN:
        title = 'Giriş Yap';
        break;
      case router.SIGNUP:
        title = 'Hesap Oluştur';
        break;
      case router.ACCOUNT:
        title = 'Hesabım';
        break;
      case router.PROFILE:
        title = 'Hesabını Düzenle';
        break;
      case router.CALCULATOR:
        title = FirebaseService.getValue(FirebaseService.calculator_page_title) ?? FirebaseService.calculator_page_title;
        break;
      case router.NOTIFICATIONS:
        title = FirebaseService.getValue(FirebaseService.notifications_header) ?? FirebaseService.notifications_header;
        break;
      case router.HOW_IT_WORKS:
        title = 'Nasıl Kazanırım?';
        break;
      case router.CONTACT_US:
        title = 'Bize Ulaş';
        break;
    }
    return !!title ? <h1 className="title">{title}</h1> : null;
  }
  
  return (
    <div id="app-header">
      <div className={`app-header-content ${!!renderPageTitle() && "with-title"}`}>
        <Menu className="menu" mode="horizontal">
          {renderLogo()}
          {renderCampaigns()}
          {renderProfile()}
          {renderDrawer()}
          {
            isLogoutModalVisible &&
            <LogoutModal
              callback={isApproved => handleLogoutModal(isApproved)}
            />
          }
        </Menu>
        {renderPageTitle()}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setMe,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    pathname: store.setPathname.pathname,
    me: store.setMe.me,
    setUnreadNotificationIdsState: store.setUnreadNotificationIds,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);

