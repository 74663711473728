import React, {ReactNode} from "react";
import "./WarningCard.scss";

interface IProps {
  title: string;
  text: string;
  action: ReactNode;
  callback: () => void;
}

const WarningCard = (props: IProps) => {

  return (
    <div id="warning-card">
      <span className="title">{props.title}</span>
      <span className="text">{props.text}</span>
      <div className="action" onClick={props.callback}>
        {props.action}
      </div>
    </div>
  );
}

export default WarningCard;
