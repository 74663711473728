import {Breadcrumb, Button} from "antd";
import React, {Component, ReactNode} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import separator from "../../../assets/images/breadcrumb-separator.svg";
import earnRewards from "../../../assets/images/earn-rewards.svg";
import onlineShopping from "../../../assets/images/online-shopping-2.svg";
import onlineShopping2 from "../../../assets/images/online-shopping-3.svg";
import FirebaseKey from "../../../components/FirebaseKey/FirebaseKey";
import FirebaseService from "../../../core/services/firebaseService/firebase.service";
import {history} from "../../../core/utilities/history";
import {IStore} from "../../../core/utilities/reducers";
import {router} from "../../../core/utilities/router";
import "./HowItWorksPage.scss";

interface IProps {
}

interface IState {
  step: HowItWorksStep;
}

export enum HowItWorksStep {
  step1,
  step2,
  step3,
}

class HowItWorksPage extends Component<IProps> {
  state: IState = {
    step: HowItWorksStep.step1,
  };
  
  componentDidMount() {
    FirebaseService.logEvent(FirebaseService.how_it_works_page_1_view);
  }
  
  private handleContinueZero(): void {
    FirebaseService.logEvent(FirebaseService.how_it_works_page_1_view);
    this.setState({step: HowItWorksStep.step1});
  }
  
  private handleContinueOne(): void {
    FirebaseService.logEvent(FirebaseService.how_it_works_page_2_view);
    this.setState({step: HowItWorksStep.step2});
  }
  
  private handleContinueTwo(): void {
    FirebaseService.logEvent(FirebaseService.how_it_works_page_3_view);
    this.setState({step: HowItWorksStep.step3});
  }
  
  private handleContinueThree(): void {
    history.push(router.LANDING);
  }
  
  private renderStep1(): ReactNode {
    return (
      <div className="page-content">
        <div className="step-content">
          <FirebaseKey
            className="section-title"
            firebaseKey={FirebaseService.onb_step1_title}
          />
          <FirebaseKey
            className="section-description"
            firebaseKey={FirebaseService.onb_step1_desc}
          />
          {
            <Breadcrumb
              className="breadcrumb"
              separator={<img src={separator} alt="arrow"/>}
            >
              <Breadcrumb.Item
                className={this.getItemClass(HowItWorksStep.step1)}
              >
                <span onClick={() => this.handleContinueZero()}>1. Adım</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className={this.getItemClass(HowItWorksStep.step2)}
              >
                <span onClick={() => this.handleContinueOne()}>2. Adım</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className={this.getItemClass(HowItWorksStep.step3)}
              >
                <span onClick={() => this.handleContinueTwo()}>3. Adım</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          }
          <Button
            className="app-button"
            onClick={() => this.handleContinueOne()}
          >
            <FirebaseKey firebaseKey={FirebaseService.onb_step1_button}/>
          </Button>
        </div>
        
        <div className="image-wrapper">
          <div
            className="image"
            style={{
              background: `url("${onlineShopping}") no-repeat center`,
              backgroundSize: "cover",
            }}
          />
        </div>
      </div>
    );
  }
  
  private renderStep2(): ReactNode {
    return (
      <div className="page-content">
        <div className="step-content">
          <FirebaseKey
            className="section-title"
            firebaseKey={FirebaseService.onb_step2_title}
          />
          <FirebaseKey
            className="section-description"
            firebaseKey={FirebaseService.onb_step2_desc}
          />
          {
            <Breadcrumb
              className="breadcrumb"
              separator={<img src={separator} alt="arrow"/>}
            >
              <Breadcrumb.Item
                className={this.getItemClass(HowItWorksStep.step1)}
              >
                <span onClick={() => this.handleContinueZero()}>1. Adım</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className={this.getItemClass(HowItWorksStep.step2)}
              >
                <span onClick={() => this.handleContinueOne()}>2. Adım</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className={this.getItemClass(HowItWorksStep.step3)}
              >
                <span onClick={() => this.handleContinueTwo()}>3. Adım</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          }
          <Button
            className="app-button"
            onClick={() => this.handleContinueTwo()}
          >
            <FirebaseKey firebaseKey={FirebaseService.onb_step2_button}/>
          </Button>
        </div>
        
        <div className="image-wrapper">
          <div
            className="image"
            style={{
              background: `url("${onlineShopping2}") no-repeat center`,
              backgroundSize: "cover",
            }}
          />
        </div>
      </div>
    );
  }
  
  private renderStep3(): ReactNode {
    return (
      <div className="page-content">
        <div className="step-content">
          <FirebaseKey
            className="section-title"
            firebaseKey={FirebaseService.onb_step3_title}
          />
          <FirebaseKey
            className="section-description"
            firebaseKey={FirebaseService.onb_step3_desc}
          />
          {
            <Breadcrumb
              className="breadcrumb"
              separator={<img src={separator} alt="arrow"/>}
            >
              <Breadcrumb.Item
                className={this.getItemClass(HowItWorksStep.step1)}
              >
                <span onClick={() => this.handleContinueZero()}>1. Adım</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className={this.getItemClass(HowItWorksStep.step2)}
              >
                <span onClick={() => this.handleContinueOne()}>2. Adım</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className={this.getItemClass(HowItWorksStep.step3)}
              >
                <span onClick={() => this.handleContinueTwo()}>3. Adım</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          }
          <Button
            className="app-button"
            onClick={() => this.handleContinueThree()}
          >
            <FirebaseKey firebaseKey={FirebaseService.onb_step3_button}/>
          </Button>
        </div>
        
        <div className="image-wrapper">
          <div
            className="image"
            style={{
              background: `url("${earnRewards}") no-repeat center`,
              backgroundSize: "cover",
            }}
          />
        </div>
      </div>
    );
  }
  
  private isStepActive(item: HowItWorksStep): boolean {
    return item === this.state.step;
  }
  
  private getItemClass(item: HowItWorksStep): string {
    return `item ${this.isStepActive(item) && "active"}`;
  }
  
  private renderStepContent(): ReactNode {
    switch (this.state.step) {
      case HowItWorksStep.step1:
        return this.renderStep1();
      case HowItWorksStep.step2:
        return this.renderStep2();
      case HowItWorksStep.step3:
        return this.renderStep3();
      default:
        return <React.Fragment/>;
    }
  }
  
  render() {
    return (
      <div id="how-it-works-page" className="page">
        {this.renderStepContent()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};
const mapStateToProps = (store: IStore) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HowItWorksPage);
