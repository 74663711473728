import {Dispatch} from 'redux';
import {ErrorDto} from '../../../models/dtos/error.dto';
import {OtpRequest} from '../../../models/requests/otp.request';
import {Errors} from '../../../utilities/errors';
import {history} from "../../../utilities/history";
import {LocalStorage} from "../../../utilities/localStorage";
import {router} from "../../../utilities/router";
import {LOGIN_OTP_FAIL, LOGIN_OTP_RESET, LOGIN_OTP_START, LOGIN_OTP_SUCCESS} from './constants';
import {loginOtpRequest} from './repository';
import {LoginOtpFailAction, LoginOtpResetAction, LoginOtpStartAction, LoginOtpSuccessAction} from './types';

const loginOtpStartAction = (): LoginOtpStartAction => {
  return {
    type: LOGIN_OTP_START,
  };
};
const loginOtpSuccessAction = (payload: string): LoginOtpSuccessAction => {
  return {
    type: LOGIN_OTP_SUCCESS,
    payload: payload,
  };
};
const loginOtpFailAction = (error: ErrorDto): LoginOtpFailAction => {
  return {
    type: LOGIN_OTP_FAIL,
    error: error,
  };
};
const loginOtpResetAction = (): LoginOtpResetAction => {
  return {
    type: LOGIN_OTP_RESET,
  };
};

export const loginOtp = (request: OtpRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(loginOtpStartAction());
    await loginOtpRequest(request);
    dispatch(loginOtpSuccessAction(request.msisdn));
  } catch (error) {
    if (Errors.isAuthError(error)) {
      LocalStorage.set(LocalStorage.msisdn, request.msisdn);
      history.push(router.SIGNUP);
    }
    dispatch(loginOtpFailAction(Errors.getErrorDtoFromApiError(error)));
  }
};

export const loginOtpReset = () => (dispatch: Dispatch) => {
  dispatch(loginOtpResetAction());
};
