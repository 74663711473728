import {Button, Modal} from 'antd';
import parse from "html-react-parser";
import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import close from "../../../assets/images/modal-close.svg";
import {CampaignDto} from "../../../core/models/dtos/campaign.dto";
import {MeDto} from "../../../core/models/dtos/me.dto";
import {redirectCampaign, redirectCampaignReset} from '../../../core/services/campaignService/redirectCampaign/actions';
import FirebaseService from "../../../core/services/firebaseService/firebase.service";
import {IStore} from "../../../core/utilities/reducers";
import {UI} from '../../../core/utilities/ui';
import CampaignTimeLeft from "../../CampaignTimeLeft/CampaignTimeLeft";
import './CampaignDetailModal.scss';

interface IProps {
  me?: MeDto;
  campaign: CampaignDto;
  isRedirectLoading: boolean;
  callbackRedirectSite: (campaign: CampaignDto) => void;
  callbackClose: () => void;
}

const CampaignDetailModal = (props: IProps) => {
  
  useEffect(() => {
    if (!!props.me) {
      FirebaseService.logEvent(FirebaseService.detail_view_total_with_login);
      FirebaseService.logEvent(`campaign_${props.campaign.id}_detail_view_total_with_login`);
    } else {
      FirebaseService.logEvent(FirebaseService.detail_view_total);
      FirebaseService.logEvent(`campaign_${props.campaign.id}_detail_view_total`);
    }
  }, [props.me, props.campaign.id]);
  
  return (
    <Modal
      className="campaign-detail-modal"
      maskStyle={UI.modalMaskStyle()}
      visible
      closable
      footer={false}
      centered
      destroyOnClose
      onCancel={props.callbackClose}
      closeIcon={<img src={close} alt="close"/>}
    >
      <div className="content">
        <h1 className="title">{props.campaign.title}</h1>
        <div
          className="image"
          style={{background: `url("${props.campaign.imageUrl}") no-repeat center`, backgroundSize: "contain"}}
        />
        <h2 className="sub-title">{props.campaign.name}</h2>
        <p className="text">
          {parse(props.campaign.description)}
        </p>
        <div className="bottom">
          <CampaignTimeLeft
            className="order-1 order-lg-0"
            endDate={props.campaign.end}
          />
          <Button
            className="app-button order-0 order-lg-1"
            disabled={props.isRedirectLoading}
            onClick={() => props.callbackRedirectSite(props.campaign)}
          >
            Siteye Git
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      redirectCampaign,
      redirectCampaignReset,
    },
    dispatch,
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    me: store.setMe.me,
    redirectCampaignState: store.redirectCampaign,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetailModal);
