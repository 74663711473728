import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {NotificationDto} from "../../../core/models/dtos/notification.dto";
import {setUnreadNotificationIds} from "../../../core/services/appService/setUnreadNotificationIds/actions";
import {SetUnreadNotificationIdsState} from "../../../core/services/appService/setUnreadNotificationIds/types";
import FirebaseService from "../../../core/services/firebaseService/firebase.service";
import {LocalStorage} from "../../../core/utilities/localStorage";
import {IStore} from "../../../core/utilities/reducers";
import Notification from "./Notification/Notification";
import "./NotificationsPage.scss";

interface IProps {
  setUnreadNotificationIdsState: SetUnreadNotificationIdsState;
  setUnreadNotificationIds: (data: number[]) => void;
}

interface IState {
  notifications: NotificationDto[];
  newNotificationIds: number[];
}

class NotificationsPage extends Component<IProps> {
  state: IState = {
    notifications: [],
    newNotificationIds: [],
  }

  componentDidMount() {
    FirebaseService.logEvent(FirebaseService.notifications_view);
    const newNotificationIds = this.props.setUnreadNotificationIdsState.data ?? [];
    LocalStorage.addSeenNotificationIds(newNotificationIds);
    this.props.setUnreadNotificationIds([]);
    this.setState({
      notifications: FirebaseService.getNotifications(),
      newNotificationIds,
    });
  }

  render() {
    return (
      <div id="notifications-page" className="page">
        <div className="page-content">
          <div className="step-content">
            <div className="notifications">
              {this.state.notifications.map(notification => {
                return (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    isNew={this.state.newNotificationIds.includes(notification.id)}
                  />
                );
              })}
            </div>
          </div>
          <div className="image-wrapper">
            <div className="image"/>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setUnreadNotificationIds,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    setUnreadNotificationIdsState: store.setUnreadNotificationIds,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPage);
