import {Button} from "antd";
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {setCookieAgreement} from "../../core/services/appService/setCookieAgreement/actions";
import {SetCookieAgreementState} from "../../core/services/appService/setCookieAgreement/types";
import {Constants} from "../../core/utilities/constants";
import {IStore} from "../../core/utilities/reducers";
import "./CookieAgreement.scss";

interface IProps {
  setCookieAgreementState: SetCookieAgreementState;
  setCookieAgreement: (data: boolean) => void;
}

const CookieAgreement = (props: IProps) => {
  
  return (
    <div
      id="cookie-agreement"
      className={!!props.setCookieAgreementState.data ? 'hidden' : ''}
    >
      <span className="text">
        MetaByte Kazan’dan en iyi şekilde yararlanabilmen için çerezler kullanıyoruz.
      </span>
      <div className="buttons">
        <Button
          className="app-button order-0 order-md-1"
          onClick={() => props.setCookieAgreement(true)}
        >
          Tamam
        </Button>
        <Button
          className="app-button outline order-1 order-md-0"
          onClick={() => props.setCookieAgreement(false)}
        >
          <a href={Constants.linkCookieDetails} target="_blank" rel="noreferrer noopener">
            Detaylar
          </a>
        </Button>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setCookieAgreement,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    setCookieAgreementState: store.setCookieAgreement,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CookieAgreement);
