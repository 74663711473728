import {Button, Modal} from "antd";
import React, {ReactNode, useEffect} from 'react';
import FirebaseService from "../../../core/services/firebaseService/firebase.service";
import {UI} from "../../../core/utilities/ui";
import "./LogoutModal.scss";

interface IProps {
  callback: (isApproved: boolean) => void;
}

const LogoutModal = (props: IProps) => {
  
  useEffect(() => {
    FirebaseService.logEvent(FirebaseService.sign_out_popup_view);
  }, []);
  
  function renderButtonsMobile(): ReactNode {
    return (
      <div className="d-flex d-md-none button-wrapper mobile">
        <Button
          className="app-button ok"
          onClick={() => {
            FirebaseService.logEvent(FirebaseService.sign_out_on_popup_clicked);
            props.callback(true);
          }}
        >
          Çıkış Yap
        </Button>
        <Button
          className="app-button cancel"
          onClick={() => props.callback(false)}
        >
          Vazgeç
        </Button>
      </div>
    );
  }
  
  function renderButtonsDesktop(): ReactNode {
    return (
      <div className="d-none d-md-flex button-wrapper">
        <Button
          className="app-button cancel"
          onClick={() => props.callback(false)}
        >
          Vazgeç
        </Button>
        <Button
          className="app-button ok"
          onClick={() => props.callback(true)}
        >
          Çıkış Yap
        </Button>
      </div>
    );
  }
  
  return (
    <Modal
      wrapClassName="logout-modal-wrapper"
      className="logout-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
      onCancel={() => props.callback(false)}
    >
      <div className="content">
        <label className="title">
          Çıkış yapıyorsun
        </label>
        <div className="line-wrapper d-none d-md-flex">
          <span className="line"/>
        </div>
        <label className="text">
          Kazanmaya devam edebilmek için giriş yapmış olman gerekiyor. Çıkış yapmak istediğine emin misin?
        </label>
        {renderButtonsMobile()}
        {renderButtonsDesktop()}
      </div>
    </Modal>
  );
}

export default LogoutModal;
