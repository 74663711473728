import React, {Component, ReactNode} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {MeDto} from "../../../core/models/dtos/me.dto";
import {getCampaigns} from "../../../core/services/campaignService/getCampaigns/actions";
import {GetCampaignsState} from "../../../core/services/campaignService/getCampaigns/types";
import FirebaseService from "../../../core/services/firebaseService/firebase.service";
import {history} from "../../../core/utilities/history";
import {LocalStorage} from "../../../core/utilities/localStorage";
import {IStore} from "../../../core/utilities/reducers";
import "./LandingPage.scss";
import SectionBanner from "./SectionBanner/SectionBanner";
import SectionCampaigns from "./SectionCampaigns/SectionCampaigns";
import SectionHowItWorks from "./SectionHowItWorks/SectionHowItWorks";
import SectionKgbApp from "./SectionKgbApp/SectionKgbApp";
import SectionSlider from "./SectionSlider/SectionSlider";

interface IProps {
  me?: MeDto;
  getCampaignsState: GetCampaignsState;
  getCampaigns: () => void;
}

class LandingPage extends Component<IProps> {
  
  constructor(props: IProps) {
    super(props);
    const redirect = LocalStorage.get(LocalStorage.redirect);
    if (redirect) {
      LocalStorage.remove(LocalStorage.redirect);
      history.push(redirect);
    }
  }
  
  componentDidMount() {
    if (!!this.props.me) {
      FirebaseService.logEvent(FirebaseService.landing_view_with_login);
    } else {
      FirebaseService.logEvent(FirebaseService.landing_view_without_login);
    }
    if (!this.props.getCampaignsState.data) {
      this.props.getCampaigns();
    }
  }
  
  private getSliderImages(): string[] {
    if (this.props.getCampaignsState.data) {
      return this.props.getCampaignsState.data.map(campaign => campaign.imageUrl);
    }
    return [];
  }
  
  private renderDivider(): ReactNode {
    return <div className="divider"/>;
  }
  
  render() {
    return (
      <div id="landing-page" className="page">
        <div className="page-content">
          <SectionBanner me={this.props.me}/>
          {this.renderDivider()}
          {
            this.props.getCampaignsState.data &&
            <SectionCampaigns
              campaigns={this.props.getCampaignsState.data}
            />
          }
          {this.renderDivider()}
          <SectionHowItWorks/>
          {this.renderDivider()}
          <SectionSlider images={this.getSliderImages()}/>
          {this.renderDivider()}
          <SectionKgbApp/>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getCampaigns,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    me: store.setMe.me,
    getCampaignsState: store.getCampaigns,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
