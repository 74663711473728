import axios from '../../../utilities/axios';
import {OtpRequest} from '../../../models/requests/otp.request';

export const signUpOtpRequest = async (request: OtpRequest): Promise<void> => {
  try {
    const response = await axios.post<void>(
      `${process.env.REACT_APP_BASE_URL}/auth/sign-up/otp`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
