import {Dispatch} from 'redux';
import {ErrorDto} from '../../../models/dtos/error.dto';
import { ContactRequest } from '../../../models/requests/contact.request';
import { Errors } from '../../../utilities/errors';
import {
  CONTACT_FAIL,
  CONTACT_RESET,
  CONTACT_START,
  CONTACT_SUCCESS,
} from './constants';
import {contactRequest} from './repository';
import {
  ContactFailAction,
  ContactResetAction,
  ContactStartAction,
  ContactSuccessAction,
} from './types';

const contactStartAction = (): ContactStartAction => {
  return {
    type: CONTACT_START,
  };
};
const contactSuccessAction = (payload: boolean): ContactSuccessAction => {
  return {
    type: CONTACT_SUCCESS,
    payload: payload,
  };
};
const contactFailAction = (error: ErrorDto): ContactFailAction => {
  return {
    type: CONTACT_FAIL,
    error: error,
  };
};
const contactResetAction = (): ContactResetAction => {
  return {
    type: CONTACT_RESET,
  };
};

export const contact = (request: ContactRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(contactStartAction());
    await contactRequest(request);
    dispatch(contactSuccessAction(true));
  } catch (error) {
    dispatch(contactFailAction(Errors.getErrorDtoFromApiError(error)));
  }
};

export const contactReset = () => (dispatch: Dispatch) => {
  dispatch(contactResetAction());
};
