import {
  CONTACT_FAIL,
  CONTACT_RESET,
  CONTACT_START,
  CONTACT_SUCCESS,
} from './constants';
import {ContactActions, ContactState} from './types';

const initialState: ContactState = {
  loading: false,
  data: false,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: ContactActions,
): ContactState => {
  switch (action.type) {
    case CONTACT_START:
      return {
        ...initialState,
        loading: true,
      };
    case CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CONTACT_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CONTACT_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
