import parse from "html-react-parser";
import React, {ReactNode} from "react";
import {NavLink} from "react-router-dom";
import {Swiper, SwiperSlide} from 'swiper/react';
import rightPrimary from "../../../../assets/images/chevron-right-primary.svg";
import CampaignDiscount from "../../../../components/CampaignDiscount/CampaignDiscount";
import FirebaseKey from "../../../../components/FirebaseKey/FirebaseKey";
import {CampaignDto} from "../../../../core/models/dtos/campaign.dto";
import FirebaseService from "../../../../core/services/firebaseService/firebase.service";
import {history} from "../../../../core/utilities/history";
import {router} from "../../../../core/utilities/router";
import "./SectionCampaigns.scss";

interface IProps {
  campaigns: CampaignDto[];
}

const SectionCampaigns = (props: IProps) => {
  
  function getCampaignsPage(group: number): CampaignDto[] {
    const start = group * 3 + 1;
    const end = Math.min(start + 3, props.campaigns.length);
    return props.campaigns.slice(start, end);
  }
  
  function handleClickCampaign(id: number): void {
    history.push(`${router.CAMPAIGNS}/${id}`);
  }
  
  function renderCampaign(campaign: CampaignDto): ReactNode {
    return (
      <div
        key={campaign.id}
        className="campaign"
        onClick={() => handleClickCampaign(campaign.id)}
      >
        <CampaignDiscount
          imageUrl={campaign.logoUrl}
          discount={campaign.discount}
        />
        <div className="text">{parse(campaign.title)}</div>
      </div>
    );
  }
  
  return (
    <section
      id="section-campaigns"
      className="section"
    >
      <FirebaseKey
        className="section-title"
        firebaseKey={FirebaseService.landing_campaigns_title}
      />
      <Swiper
        className="campaigns-swiper"
        spaceBetween={20}
        slidesPerView={1.3}
        breakpoints={{
          500: {slidesPerView: 1.6},
          600: {slidesPerView: 2.3},
          800: {slidesPerView: 3.2},
          1140: {slidesPerView: 4},
        }}
      >
        {Array.from(Array(4), (e, i) => {
          return (
            <SwiperSlide key={`campaigns-${i}`}>
              <div className="d-flex flex-column">
                {getCampaignsPage(i).map(renderCampaign)}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <NavLink to={router.CAMPAIGNS} className="link">
        Tümünü Gör
        <img className="arrow" src={rightPrimary} alt="arrow right"/>
      </NavLink>
    </section>
  );
}

export default SectionCampaigns;
