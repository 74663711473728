import React, {ReactNode} from 'react';
import {connect} from "react-redux";
import {NavLink} from 'react-router-dom';
import {bindActionCreators, Dispatch} from "redux";
import {FormValuesLogin} from "../../../core/models/custom/formValuesLogin";
import {FormValuesOtp} from "../../../core/models/custom/formValuesOtp";
import {LoginRequest} from "../../../core/models/requests/login.request";
import {OtpRequest} from "../../../core/models/requests/otp.request";
import {login, loginReset} from "../../../core/services/authService/login/actions";
import {LoginState} from '../../../core/services/authService/login/types';
import {loginOtp, loginOtpReset} from "../../../core/services/authService/loginOtp/actions";
import {LoginOtpState} from "../../../core/services/authService/loginOtp/types";
import FirebaseService from "../../../core/services/firebaseService/firebase.service";
import {LocalStorage} from "../../../core/utilities/localStorage";
import {IStore} from "../../../core/utilities/reducers";
import {router} from "../../../core/utilities/router";
import OtpForm from "../Common/OtpForm/OtpForm";
import LoginForm from "./LoginForm/LoginForm";
import './LoginPage.scss';

interface IProps {
  loginOtpState: LoginOtpState;
  loginOtp: (request: OtpRequest) => void;
  loginOtpReset: () => void;
  loginState: LoginState;
  login: (request: LoginRequest) => void;
  loginReset: () => void;
}

class LoginPage extends React.Component<IProps> {
  
  componentDidMount() {
    FirebaseService.logEvent(FirebaseService.login_view);
  }
  
  private getMsisdnInitial(): string {
    const msisdn = LocalStorage.get(LocalStorage.msisdn);
    if (msisdn) {
      LocalStorage.remove(LocalStorage.msisdn);
      return msisdn.slice(2);
    }
    return "";
  }
  
  private handleLoginForm(values: FormValuesLogin) {
    this.props.loginOtp({...values});
  }
  
  private handleOtpForm(values: FormValuesOtp): void {
    if (!this.props.loginOtpState.data) {
      alert('Msisdn not defined!');
      return;
    }
    this.props.login({
      otp: values.otp,
      msisdn: this.props.loginOtpState.data,
    });
  }
  
  private renderFormNote(): ReactNode {
    return (
      <p className="form-note">
        Hemen giriş yap! Fırsatlardan yararlan, alışveriş yaptıkça kazanmaya devam et!
      </p>
    );
  }
  
  private renderStepLogin(): ReactNode {
    return (
      <React.Fragment>
        {this.renderFormNote()}
        <LoginForm
          msisdnInitial={this.getMsisdnInitial()}
          isLoading={this.props.loginOtpState.loading}
          callbackLogin={values => this.handleLoginForm(values)}
        />
        <NavLink
          to={router.SIGNUP}
          onClick={() => FirebaseService.logEvent(FirebaseService.login_sign_up_clicked)}
        >
          <div className="link-signup">Hesap Oluştur</div>
        </NavLink>
      </React.Fragment>
    );
  }
  
  private renderStepOtp(): ReactNode {
    return (
      <React.Fragment>
        {this.renderFormNote()}
        <OtpForm
          isLoading={this.props.loginState.loading}
          callbackSubmit={values => this.handleOtpForm(values)}
        />
      </React.Fragment>
    );
  }
  
  private renderStepContent(): ReactNode {
    if (this.props.loginOtpState.data) {
      return this.renderStepOtp();
    }
    return this.renderStepLogin();
  }
  
  render() {
    return (
      <div id="login-page" className="page">
        <div className="page-content">
          <div className="step-content">
            {this.renderStepContent()}
          </div>
          <div className="image-wrapper">
            <div className="image"/>
          </div>
        </div>
      </div>
    );
  }
  
  componentWillUnmount() {
    this.props.loginOtpReset();
    this.props.loginReset();
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      loginOtp,
      loginOtpReset,
      login,
      loginReset,
    },
    dispatch,
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    loginOtpState: store.loginOtp,
    loginState: store.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
