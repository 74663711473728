import {LocalStorage} from "../../../utilities/localStorage";
import {SET_COOKIE_AGREEMENT} from "./constants";
import {SetCookieAgreementActions, SetCookieAgreementState} from "./types";

const initialState: SetCookieAgreementState = {
  data: LocalStorage.get(LocalStorage.cookieAgreement) ? true : undefined,
};

const reducer = (
  state = initialState,
  action: SetCookieAgreementActions,
): SetCookieAgreementState => {
  switch (action.type) {
    case SET_COOKIE_AGREEMENT:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
