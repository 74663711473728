import React, { ReactNode } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import image1 from "../../../../assets/images/how-it-works-1.svg";
import image2 from "../../../../assets/images/how-it-works-2.svg";
import image3 from "../../../../assets/images/how-it-works-3.svg";
import image4 from "../../../../assets/images/how-it-works-4.svg";
import FirebaseKey from "../../../../components/FirebaseKey/FirebaseKey";
import FirebaseService from "../../../../core/services/firebaseService/firebase.service";
import "./SectionHowItWorks.scss";
import WorkCard from "./WorkCard/WorkCard";

interface IProps {
}

export interface IWorkCard {
  step: number;
  image: ReactNode;
  text: string;
}

const SectionHowItWorks = (props: IProps) => {

  const workCards: IWorkCard[] = [
    {
      step: 1, image: <img src={image1} alt="step" />,
      text: 'Senin için markaların fırsatlarını listeleriz.'
    },
    {
      step: 2,
      image: <img src={image2} alt="step" />,
      text: 'Sen seçtiğin markanın linkine giderek alışverişini tamamlarsın.'
    },
    {
      step: 3,
      image: <img src={image3} alt="step" />,
      text: "Yaptığın alışverişlerden MetaByte kazanırsın."
    },
    {
      step: 4,
      image: <img src={image4} alt="step" />,
      text: "Kazandığın MetaByte’lar kumbaranda birikir, dilediğinde kullanırsın."
    },
  ];

  return (
    <section
      id="section-how-it-works"
      className="section"
    >
      <div className="content">
        <FirebaseKey
          className="section-title"
          firebaseKey={FirebaseService.landing_how_does_it_work_title}
        />
        <FirebaseKey
          className="section-description"
          firebaseKey={FirebaseService.landing_how_does_it_work_desc}
        />
      </div>
      <Swiper
        className="steps-swiper"
        spaceBetween={2}
        slidesPerView={1.3}
        breakpoints={{
          500: { slidesPerView: 1.6 },
          600: { slidesPerView: 2.5 },
          800: { slidesPerView: 3.4 },
          1000: { slidesPerView: 4 },
        }}
      >
        {workCards.map((card, i) => {
          return (
            <SwiperSlide key={`steps-${i}`}>
              <WorkCard workCard={card} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
}

export default SectionHowItWorks;
