import Adjust from "@adjustcom/adjust-web-sdk";
import React from 'react';
import {isSafari} from "react-device-detect";
import {connect} from 'react-redux';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import {bindActionCreators, Dispatch} from 'redux';
import './App.scss';
import AppLayout from './components/Layouts/AppLayout/AppLayout';
import Loading from './components/Loading/Loading';
import ApiErrorModal from './components/Modals/ApiErrorModal/ApiErrorModal';
import FirstTimeModal from "./components/Modals/FirstTimeModal/FirstTimeModal";
import SafariWarning from "./components/SafariWarning/SafariWarning";
import AccountPage from "./containers/App/AccountPage/AccountPage";
import CalculatorPage from "./containers/App/CalculatorPage/CalculatorPage";
import CampaignsPage from "./containers/App/CampaignsPage/CampaignsPage";
import ContactUsPage from "./containers/App/ContactUsPage/ContactUsPage";
import FaqPage from "./containers/App/FaqPage/FaqPage";
import HowItWorksPage from "./containers/App/HowItWorksPage/HowItWorksPage";
import LandingPage from "./containers/App/LandingPage/LandingPage";
import NotFoundPage from "./containers/App/NotFoundPage/NotFoundPage";
import NotificationsPage from "./containers/App/NotificationsPage/NotificationsPage";
import ProfilePage from "./containers/App/ProfilePage/ProfilePage";
import LoginPage from './containers/Auth/LoginPage/LoginPage';
import SignUpPage from "./containers/Auth/SignUpPage/SignUpPage";
import {ErrorDto} from "./core/models/dtos/error.dto";
import {MeDto} from "./core/models/dtos/me.dto";
import {checkToken} from "./core/services/appService/checkToken/actions";
import {CheckTokenState} from "./core/services/appService/checkToken/types";
import {setApiError} from './core/services/appService/setApiError/actions';
import {setAppMounted} from './core/services/appService/setAppMounted/actions';
import {SetAppMountedState} from './core/services/appService/setAppMounted/types';
import {setMe} from "./core/services/appService/setMe/actions";
import {setPathname} from './core/services/appService/setPathname/actions';
import {setUnreadNotificationIds} from "./core/services/appService/setUnreadNotificationIds/actions";
import {SetUnreadNotificationIdsState} from './core/services/appService/setUnreadNotificationIds/types';
import {setRemoteConfig} from './core/services/firebaseService/setRemoteConfig/actions';
import {SetRemoteConfigState} from './core/services/firebaseService/setRemoteConfig/types';
import axios from './core/utilities/axios';
import {Errors} from './core/utilities/errors';
import {Helpers} from "./core/utilities/helpers";
import {history} from './core/utilities/history';
import {IStore} from './core/utilities/reducers';
import {router} from './core/utilities/router';

interface IProps {
  setAppMountedState: SetAppMountedState;
  setAppMounted: (isMounted: boolean) => void;
  setRemoteConfigState: SetRemoteConfigState;
  setRemoteConfig: () => void;
  setPathname: (pathname: string) => void;
  setApiError: (error?: ErrorDto) => void;
  checkTokenState: CheckTokenState;
  checkToken: () => void;
  me?: MeDto;
  setMe: (me?: MeDto) => void;
  setUnreadNotificationIdsState: SetUnreadNotificationIdsState;
  setUnreadNotificationIds: () => void;
}

interface IState {
  callbackHistoryUnregister?: VoidFunction;
}

class App extends React.Component<IProps> {
  state: IState = {
    callbackHistoryUnregister: undefined,
  };
  
  constructor(props: IProps) {
    super(props);
    this.setAdjust();
    this.setAxiosInterceptor();
    this.props.setRemoteConfig();
  }
  
  componentDidMount() {
    this.setHistoryListener();
    this.props.checkToken();
    this.props.setAppMounted(true);
  }
  
  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (Helpers.isFreshData(prevProps.setRemoteConfigState, this.props.setRemoteConfigState)) {
      this.props.setUnreadNotificationIds();
    }
  }
  
  private setAdjust(): void {
    Adjust.initSdk({
      appToken: `${process.env.REACT_APP_ADJUST_APP_TOKEN}`,
      environment: Helpers.isEnvProd() ? 'production' : 'sandbox',
    });
  }
  
  private setAxiosInterceptor(): void {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (Errors.isAuthError(error)) {
          if (Errors.getParsedErrorDtoFromApiError(error).config.url.includes('/auth')) {
            // Do not show modal for login signup errors. Just redirect.
            // this.props.setApiError(Errors.getErrorDtoFromApiError(error));
          } else {
            this.props.setMe();
          }
        } else {
          this.props.setApiError(Errors.getErrorDtoFromApiError(error));
        }
        return Promise.reject(error);
      },
    );
  }
  
  private setHistoryListener(): void {
    const callbackHistoryUnregister = history.listen((location: any) => {
      this.props.setPathname(location.pathname);
    });
    this.setState({callbackHistoryUnregister});
  }
  
  private isAppLoading(): boolean {
    return (
      !this.props.setAppMountedState.isMounted ||
      this.props.setRemoteConfigState.loading ||
      !this.props.setUnreadNotificationIdsState.data ||
      this.props.checkTokenState.loading
    );
  }
  
  private isAppVisible(): boolean {
    return !!this.props.me;
  }
  
  render() {
    return (
      <Router history={history}>
        {
          this.isAppLoading()
            ?
            <Loading className="app-loading" fontSize={80}/>
            :
            this.isAppVisible()
              ?
              <AppLayout>
                <Switch>
                  <Route exact path={router.LANDING} component={LandingPage}/>
                  <Route exact path={router.CAMPAIGNS} component={CampaignsPage}/>
                  <Route exact path={`${router.CAMPAIGNS}/:campaignId`} component={CampaignsPage}/>
                  <Route exact path={router.ACCOUNT} component={AccountPage}/>
                  <Route exact path={router.PROFILE} component={ProfilePage}/>
                  <Route exact path={router.HOW_IT_WORKS} component={HowItWorksPage}/>
                  <Route exact path={router.CONTACT_US} component={ContactUsPage}/>
                  <Route exact path={router.FAQ} component={FaqPage}/>
                  <Route exact path={router.NOTIFICATIONS} component={NotificationsPage}/>
                  <Route exact path={router.CALCULATOR} component={CalculatorPage}/>
                  <Route exact path={router.NOT_FOUND} component={NotFoundPage}/>
                  <Redirect to={router.LANDING}/>
                </Switch>
              </AppLayout>
              :
              <AppLayout>
                <Switch>
                  <Route exact path={router.LANDING} component={LandingPage}/>
                  <Route exact path={router.LOGIN} component={LoginPage}/>
                  <Route exact path={router.SIGNUP} component={SignUpPage}/>
                  <Route exact path={router.CAMPAIGNS} component={CampaignsPage}/>
                  <Route exact path={`${router.CAMPAIGNS}/:campaignId`} component={CampaignsPage}/>
                  <Route exact path={router.HOW_IT_WORKS} component={HowItWorksPage}/>
                  <Route exact path={router.CONTACT_US} component={ContactUsPage}/>
                  <Route exact path={router.FAQ} component={FaqPage}/>
                  <Route exact path={router.NOTIFICATIONS} component={NotificationsPage}/>
                  <Route exact path={router.CALCULATOR} component={CalculatorPage}/>
                  <Route exact path={router.NOT_FOUND} component={NotFoundPage}/>
                  <Redirect to={router.LANDING}/>
                </Switch>
              </AppLayout>
        }
        {isSafari && <SafariWarning/>}
        <FirstTimeModal/>
        <ApiErrorModal/>
      </Router>
    );
  }
  
  componentWillUnmount() {
    if (this.state.callbackHistoryUnregister) {
      this.state.callbackHistoryUnregister();
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setAppMounted,
      setRemoteConfig,
      setPathname,
      setApiError,
      checkToken,
      setMe,
      setUnreadNotificationIds,
    },
    dispatch,
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    setAppMountedState: store.setAppMounted,
    setRemoteConfigState: store.setRemoteConfig,
    checkTokenState: store.checkToken,
    me: store.setMe.me,
    setUnreadNotificationIdsState: store.setUnreadNotificationIds,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
