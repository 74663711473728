import {Button, Form, Input} from "antd";
import React, {useState} from 'react';
import {FormError} from "../../../../core/models/custom/formError";
import {FormValuesProfile} from "../../../../core/models/custom/formValuesProfile";
import {MeDto} from "../../../../core/models/dtos/me.dto";
import "./ProfileForm.scss";

interface IProps {
  me?: MeDto;
  isLoading: boolean;
  callbackSubmit: (values: FormValuesProfile) => void;
}

const ProfileForm = (props: IProps) => {
  const [isEmailError, setEmailError] = useState(false);
  const [form] = Form.useForm();
  
  function getInitialValues() {
    const trimmedMsisdn = props.me?.msisdn ? props.me.msisdn.slice(2) : undefined;
    return {
      nameSurname: props.me?.nameSurname,
      email: props.me?.email,
      msisdn: trimmedMsisdn,
    }
  }
  
  function handleFail(e: any): void {
    setEmailError((e as FormError).errorFields.filter(err => err.name.includes('email')).length > 0);
  }
  
  function handleFinish(values: FormValuesProfile): void {
    setEmailError(false);
    props.callbackSubmit(values);
  }
  
  return (
    <Form
      form={form}
      id="profile-form"
      initialValues={getInitialValues()}
      onFinishFailed={handleFail}
      onFinish={handleFinish}
    >
      <div className="app-input">
        <div className="input-wrapper">
          <span className="label">Adın Soyadın</span>
          <Form.Item
            name="nameSurname"
            className="input"
          >
            <Input/>
          </Form.Item>
        </div>
      </div>
      
      <div className="app-input disabled">
        <span className="prefix">+90</span>
        <div className="input-wrapper">
          <span className="label">Telefon numaran</span>
          <Form.Item
            name="msisdn"
            className="input"
          >
            <Input disabled/>
          </Form.Item>
        </div>
      </div>
      
      <span className="msisdn-note disabled">
      Telefon numaranı değiştirmek için Bize Ulaş üzerinden bizimle iletişime geçebilirsin.
      </span>
      
      <div className={`app-input ${isEmailError && 'error'}`}>
        <div className="input-wrapper">
          <span className="label">E- mail Adresin</span>
          <Form.Item
            name="email"
            className="input"
            rules={[
              {required: false, message: ''},
              {type: 'email', message: ''},
            ]}
          >
            <Input/>
          </Form.Item>
        </div>
      </div>
      
      <Button
        htmlType="submit"
        className="app-button"
        disabled={props.isLoading}
      >
        Devam Et
      </Button>
    </Form>
  );
}

export default ProfileForm;
