import {Button} from "antd";
import React from 'react';
import {NavLink} from "react-router-dom";
import rightBlack from "../../../../assets/images/chevron-right-black.svg";
import rightPrimary from "../../../../assets/images/chevron-right-primary.svg";
import FirebaseKey from "../../../../components/FirebaseKey/FirebaseKey";
import {MeDto} from "../../../../core/models/dtos/me.dto";
import FirebaseService from "../../../../core/services/firebaseService/firebase.service";
import {router} from "../../../../core/utilities/router";
import BannerCarousel from "./BannerCarousel/BannerCarousel";
import "./SectionBanner.scss";

interface IProps {
  me?: MeDto;
}

const SectionBanner = (props: IProps) => {
  
  return (
    <section
      id="section-banner"
      className="section"
    >
      <div className="content">
        <FirebaseKey
          className="page-title"
          firebaseKey={FirebaseService.landing_main_title}
        />
        <FirebaseKey
          className="section-description"
          firebaseKey={FirebaseService.landing_main_desc}
        />
        <div className="actions">
          {
            !props.me &&
            <NavLink
              to={router.LOGIN}
              onClick={() => FirebaseService.logEvent(FirebaseService.login_clicked)}
            >
              <Button className="app-button">
                Kazanmaya Başla
                <img className="arrow" src={rightBlack} alt="arrow right"/>
              </Button>
            </NavLink>
          }
          <NavLink
            to={router.HOW_IT_WORKS}
            onClick={() => FirebaseService.logEvent(FirebaseService.how_it_works_clicked)}
          >
            <div className="link-button">
              Nasıl Kazanırım?
              <img className="arrow" src={rightPrimary} alt="arrow right"/>
            </div>
          </NavLink>
        </div>
      </div>
      <BannerCarousel/>
    </section>
  );
}

export default SectionBanner;
