import {Dispatch} from 'redux';
import {CampaignFilterDto} from '../../../models/custom/campaignFilter.dto';
import {SET_CAMPAIGN_FILTER} from './constants';
import {SetCampaignFilterAction} from './types';

const setCampaignFilterAction = (payload: CampaignFilterDto): SetCampaignFilterAction => {
  return {
    type: SET_CAMPAIGN_FILTER,
    payload: payload,
  };
};

export const setCampaignFilter = (data: CampaignFilterDto) => async (
  dispatch: Dispatch,
) => {
  dispatch(setCampaignFilterAction(data));
};
