import {LOGIN_OTP_START, LOGIN_OTP_SUCCESS, LOGIN_OTP_FAIL, LOGIN_OTP_RESET} from './constants';
import {LoginOtpActions, LoginOtpState} from './types';

const initialState: LoginOtpState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: LoginOtpActions,
): LoginOtpState => {
  switch (action.type) {
    case LOGIN_OTP_START:
      return {
        ...initialState,
        loading: true,
      };
    case LOGIN_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case LOGIN_OTP_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case LOGIN_OTP_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
