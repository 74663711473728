import {Input} from "antd";
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import prefix from "../../../../assets/images/campaign-search-prefix.svg";
import suffixActive from "../../../../assets/images/campaign-search-suffix-active.svg";
import suffix from "../../../../assets/images/campaign-search-suffix.svg";
import {CampaignFilterDto} from "../../../../core/models/custom/campaignFilter.dto";
import {setCampaignFilter} from "../../../../core/services/appService/setCampaignFilter/actions";
import {SetCampaignFilterState} from "../../../../core/services/appService/setCampaignFilter/types";
import {Helpers} from "../../../../core/utilities/helpers";
import {IStore} from "../../../../core/utilities/reducers";
import "./SearchBar.scss";

interface IProps {
  callbackClickFilter: () => void;
  setCampaignFilterState: SetCampaignFilterState;
  setCampaignFilter: (filter: CampaignFilterDto) => void;
}

const SearchBar = (props: IProps) => {

  function handleChangeQuery(query: string): void {
    const filter = props.setCampaignFilterState.data;
    props.setCampaignFilter({
      ...filter,
      query,
    });
  }

  return (
    <div id="search-bar">
      <div className="search-bar-content">
        <h1 className="title">Fırsatlar</h1>
        <div className="search-wrapper">
          <Input
            className="search"
            prefix={<img src={prefix} className="mr-2" alt="search"/>}
            suffix={
              <img
                className="suffix"
                src={Helpers.isCampaignFilterActive(props.setCampaignFilterState.data) ? suffixActive : suffix}
                alt="filter"
                onClick={props.callbackClickFilter}
              />
            }
            value={props.setCampaignFilterState.data.query}
            onChange={e => handleChangeQuery(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setCampaignFilter,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    setCampaignFilterState: store.setCampaignFilter,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
