import React, {ReactNode} from "react";
import {NavLink} from "react-router-dom";
import {NotificationButton, NotificationDto} from "../../../../core/models/dtos/notification.dto";
import {Helpers} from "../../../../core/utilities/helpers";
import "./Notification.scss";

interface IProps {
  notification: NotificationDto;
  isNew: boolean;
}

const Notification = (props: IProps) => {

  function getTime(): string {
    const minutes = Helpers.getTimeDiffWithToday(props.notification.date, "minutes");
    const hours = Helpers.getTimeDiffWithToday(props.notification.date, "hours");
    const days = Helpers.getTimeDiffWithToday(props.notification.date, "days");
    const months = Helpers.getTimeDiffWithToday(props.notification.date, "months");
    const years = Helpers.getTimeDiffWithToday(props.notification.date, "years");
    if (years > 0) {
      return `${years} yıl`;
    } else if (months > 0) {
      return `${months} ay`;
    } else if (days > 0) {
      return `${days} gün`;
    } else if (hours > 0) {
      return `${hours}s`;
    } else if (minutes > 0) {
      return `${minutes}dk`;
    } else {
      return 'Az önce';
    }
  }

  function getButtonClass(index: number, isNavLink: boolean): string {
    return `button ${isNavLink ? 'app-button' : ''} order-${props.notification.buttons!.length - index}`;
  }

  function renderButton(button: NotificationButton, index: number): ReactNode {
    if (button.buttonLink.startsWith('/')) {
      return (
        <NavLink
          key={button.buttonLink}
          to={button.buttonLink}
          className={getButtonClass(index, true)}
        >
          {button.buttonText}
        </NavLink>
      );
    }
    return (
      <a
        key={button.buttonLink}
        href={button.buttonLink}
        className={getButtonClass(index, false)}
        rel="noreferrer noopenner"
        target="_blank"
      >
        {button.buttonText}
      </a>
    );
  }

  return (
    <div
      id="notification"
      className={props.isNew ? 'new' : ''}
    >
      {
        props.notification.image &&
        <img className="image" src={props.notification.image} alt="banner"/>
      }
      <div className="content">
        <div className="data">
          <h1 className="header">{props.notification.header}</h1>
          <p className="desc">{props.notification.desc}</p>
          <span className="date">{getTime()}</span>
        </div>
        <div className="buttons">
          {props.notification.buttons?.map((button, index) => renderButton(button, index))}
        </div>
      </div>
    </div>
  );
}

export default Notification;
