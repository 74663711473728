import React from "react";
import anon from "../../../../assets/images/anon-avatar.svg";
import {MeDto} from "../../../../core/models/dtos/me.dto";
import "./ProfileCard.scss";

interface IProps {
  me: MeDto;
}

const ProfileCard = (props: IProps) => {

  function getFullNameInitials(fullName: string): string {
    const data = fullName.split(" ");
    return `${data[0].charAt(0).toUpperCase()}${data[1] ? data[1].charAt(0).toUpperCase() : ''}`;
  }

  return (
    <div id="profile-card">
      <div className="avatar">
        {
          props.me.nameSurname
            ?
            <span className="initials">
              {getFullNameInitials(props.me.nameSurname)}
            </span>
            :
            <img src={anon} alt="anon"/>
        }
      </div>
      <div className="info">
        <span className="fullName">
          {!!props.me.nameSurname ? props.me.nameSurname : ''}
        </span>
        <span className="msisdn">
          {props.me.msisdn}
        </span>
        {
          props.me.email &&
          <div className="email-wrapper">
            <span className="email">
              {props.me.email}
            </span>
          </div>
        }
      </div>
    </div>
  );
}

export default ProfileCard;
