import {Dispatch} from 'redux';
import {ErrorDto} from '../../../models/dtos/error.dto';
import {RedirectDto} from '../../../models/dtos/redirect.dto';
import {Errors} from '../../../utilities/errors';
import {
  REDIRECT_CAMPAIGN_FAIL,
  REDIRECT_CAMPAIGN_RESET,
  REDIRECT_CAMPAIGN_START,
  REDIRECT_CAMPAIGN_SUCCESS
} from './constants';
import {redirectCampaignRequest} from "./repository";
import {
  RedirectCampaignFailAction,
  RedirectCampaignResetAction,
  RedirectCampaignStartAction,
  RedirectCampaignSuccessAction
} from './types';

const redirectCampaignStartAction = (): RedirectCampaignStartAction => {
  return {
    type: REDIRECT_CAMPAIGN_START,
  };
};
const redirectCampaignSuccessAction = (payload: RedirectDto): RedirectCampaignSuccessAction => {
  return {
    type: REDIRECT_CAMPAIGN_SUCCESS,
    payload: payload,
  };
};
const redirectCampaignFailAction = (error: ErrorDto): RedirectCampaignFailAction => {
  return {
    type: REDIRECT_CAMPAIGN_FAIL,
    error: error,
  };
};
const redirectCampaignResetAction = (): RedirectCampaignResetAction => {
  return {
    type: REDIRECT_CAMPAIGN_RESET,
  };
};

export const redirectCampaign = (campaignId: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(redirectCampaignStartAction());
    const response = await redirectCampaignRequest(campaignId);
    window.open(response.url, '_blank', 'noreferrer');
    dispatch(redirectCampaignSuccessAction(response));
  } catch (error) {
    dispatch(redirectCampaignFailAction(Errors.getErrorDtoFromApiError(error)));
  }
};

export const redirectCampaignReset = () => (dispatch: Dispatch) => {
  dispatch(redirectCampaignResetAction());
};
