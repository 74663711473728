import {Form, InputNumber} from "antd";
import React, {useState} from 'react';
import equal from "../../../../assets/images/calculator-equal.svg";
import suffixAmount from "../../../../assets/images/calculator-suffix-amount.svg";
import suffixRatio from "../../../../assets/images/calculator-suffix-ratio.svg";
import {FormValuesCalculator} from "../../../../core/models/custom/formValuesCalculator";
import FirebaseService from "../../../../core/services/firebaseService/firebase.service";
import FirebaseKey from "../../../FirebaseKey/FirebaseKey";
import "./CalculatorModalForm.scss";

interface IProps {
}

const CalculatorModalForm = (props: IProps) => {
  const [result, setResult] = useState<number | undefined>(undefined);
  const [form] = Form.useForm();
  
  function handleChange(): void {
    const value: FormValuesCalculator = form.getFieldsValue();
    if (
      (value.amount !== null && value.amount !== undefined) &&
      (value.ratio !== null && value.ratio !== undefined)
    ) {
      setResult(value.amount * value.ratio);
    } else {
      setResult(undefined);
    }
  }
  
  function handleKeyPressAmount(e: React.KeyboardEvent): void {
    if (isNaN(parseInt(e.key)) && e.key !== '.') {
      e.preventDefault();
    }
  }
  
  function handleKeyPressRatio(e: React.KeyboardEvent): void {
    if (isNaN(parseInt(e.key)) && e.key !== '.') {
      e.preventDefault();
    }
    const currentVal = form.getFieldValue('ratio');
    if (currentVal) {
      const newValue = parseInt(`${currentVal}${e.key}`);
      if (newValue > 100) {
        e.preventDefault();
      }
    }
  }
  
  return (
    <Form
      form={form}
      id="calculator-modal-form"
      onChange={handleChange}
    >
      <FirebaseKey
        className="description"
        firebaseKey={FirebaseService.calculator_popup_desc}
      />
      
      <div className="app-input">
        <div className="input-wrapper">
          <Form.Item
            name="amount"
            className="input"
            rules={[{required: false, message: ''}]}
          >
            <InputNumber
              type="number" min={0} step={0}
              placeholder={FirebaseService.getValue(FirebaseService.calculator_popup_ph1)}
              onKeyPress={handleKeyPressAmount}
            />
          </Form.Item>
        </div>
        <img className="suffix" src={suffixAmount} alt="amount"/>
      </div>
      
      <div className="app-input">
        <div className="input-wrapper">
          <Form.Item
            name="ratio"
            className="input"
            rules={[{required: false, message: ''}]}
          >
            <InputNumber
              type="number" min={0} max={100} step={0}
              placeholder={FirebaseService.getValue(FirebaseService.calculator_popup_ph2)}
              onKeyPress={handleKeyPressRatio}
            />
          </Form.Item>
        </div>
        <img className="suffix" src={suffixRatio} alt="ratio"/>
      </div>
      
      <div className="equal">
        <img src={equal} alt="equal"/>
      </div>
      
      <div className="app-input result">
        <div className="input-wrapper">
          <Form.Item className="input">
            <InputNumber
              disabled
              placeholder={FirebaseService.getValue(FirebaseService.calculator_popup_ph3)}
              value={result}
            />
          </Form.Item>
        </div>
        {/* <img className="suffix" src={suffixResult} alt="result" /> */}
      </div>
    </Form>
  );
}

export default CalculatorModalForm;
