import {Dispatch} from 'redux';
import {LocalStorage} from "../../../utilities/localStorage";
import {SET_COOKIE_AGREEMENT} from './constants';
import {SetCookieAgreementAction} from './types';

const setCookieAgreementAction = (payload?: boolean): SetCookieAgreementAction => {
  return {
    type: SET_COOKIE_AGREEMENT,
    payload: payload,
  };
};

export const setCookieAgreement = (data?: boolean) => async (
  dispatch: Dispatch,
) => {
  if (data) {
    LocalStorage.set(LocalStorage.cookieAgreement, "true");
  }
  dispatch(setCookieAgreementAction(data));
};
