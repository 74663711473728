import { RedirectDto } from '../../../models/dtos/redirect.dto';
import axios from '../../../utilities/axios';

export const redirectCampaignRequest = async (campaignId: number): Promise<RedirectDto> => {
  try {
    const response = await axios.post<RedirectDto>(
      `${process.env.REACT_APP_BASE_URL}/campaign/${campaignId}`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
