import moment from "moment";
import 'moment/locale/tr';
import {CampaignFilterDto} from "../models/custom/campaignFilter.dto";
import {State} from "../models/custom/state";
import {Constants} from "./constants";
import {history} from './history';

export class Helpers {
  static wait = (ms: number = 1000) => new Promise((resolve) => setTimeout(resolve, ms));

  static isEnvProd(): boolean {
    const env: string = `${process.env.REACT_APP_ENV}`;
    return env === 'production';
  }

  static getQueryParam(query: string): string | null {
    const searchParams: URLSearchParams = new URLSearchParams(history.location.search);
    return searchParams.get(query);
  }

  static deleteAllCookies(): void {
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  }

  static isFreshData(prevPropsState: State, propsState: State): boolean {
    if (prevPropsState.loading && !propsState.loading) {
      if (propsState.data) {
        return true;
      }
    }
    return false;
  }

  static getLocaleDate(date?: string, isTime: boolean = true): string {
    moment.locale('tr');
    return !!date ? moment(date).format(isTime ? Constants.dateTimeFormatUI : Constants.dateFormatUI) : '';
  }

  static isCampaignFilterActive(filter: CampaignFilterDto): boolean {
    const isQuery = !!filter.query;
    const isBrand = filter.brands.filter(i => i.checked).length > 0;
    const isCategory = filter.categories.filter(i => i.checked).length > 0;
    const isRatio = !!filter.ratios.min || !!filter.ratios.max;
    return (isQuery || isBrand || isCategory || isRatio);
  }

  static getDaysDiffWithToday(endDate: string): number {
    if (!endDate) return 0;
    return moment(new Date(endDate).toUTCString()).diff(new Date().toUTCString(), 'days');
  }

  static getTimeDiffWithToday(date: string, type: "minutes" | "hours" | "days" | "months" | "years"): number {
    if (!date) return 0;
    return moment(new Date().toUTCString()).diff(new Date(date).toUTCString(), type);
  }

  static numberWithDotSeparator(x: number): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
}
