import { Collapse, Input } from 'antd';
import parse from "html-react-parser"
import React, {Component, ReactNode} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {IStore} from "../../../core/utilities/reducers";
import inputPrefix from "../../../assets/images/magnifying-glass.svg"
import PageTitle from '../../../components/PageTitle/PageTitle';
import {Faq} from "../../../core/models/custom/faq"
import FirebaseService from '../../../core/services/firebaseService/firebase.service';
import "./FaqPage.scss";

const {Panel} = Collapse;

interface IProps {
}

interface ISTate {
  faqs: Faq[];
  query: string;
}

class FaqPage extends Component<IProps> {
  state: ISTate = {
    faqs: [],
    query: '',
  }

  componentDidMount() {
    FirebaseService.logEvent(FirebaseService.faq_view);
    const faqs = FirebaseService.getFaqs();
    this.setState({faqs});
  }

  private isQueryMatch(item: string) : boolean {
    return item.toLowerCase().includes(this.state.query.toLowerCase());
  }

  private renderPageHeader(): ReactNode {
    return(
     <div id="page-header-wrapper">
       <div className='page-header-content'>
      <PageTitle text="Sık Sorulan Sorular" />
      <Input
        className="form-input search"
        prefix={<img src={inputPrefix} alt="search"  /> }
        placeholder="Sorularda ara..."
        allowClear
        onChange={e => this.setState({query: e.target.value})}
      />
    </div>
    </div>
    );
  }

  private renderFaqs(): ReactNode {
    return (
      <Collapse
        accordion
        expandIconPosition="right"
        className="faqs"
      >
        {
          this.state.faqs
            .filter(faq => this.isQueryMatch(faq.questions) || this.isQueryMatch(faq.answers))
            .map(faq => {
              return (
                <Panel
                key={faq.questions}
                header={parse(faq.questions ?? '')}
                >
                  {parse(faq.answers ?? '')}
                </Panel>
              );
            })
        }
      </Collapse>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderPageHeader()}
      <div id="faq-page" className="page">
        <div className="page-content">
          {this.renderFaqs()}
        </div>
      </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {},
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(FaqPage);
