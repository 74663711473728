import {ContactRequest} from "../../models/requests/contact.request";
import axios from "../../utilities/axios";



export default class MiscService {

  async contact(request: ContactRequest): Promise<{url: string}> {
    try {
      const formData = new FormData();
      if (request.file) {
        formData.append('file', request.file);
      }
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      const postAddress = "https://autologin.solidict.com/upload/image";
      const response = await axios.post(
        `${postAddress}`,
        formData,
        config
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
