import { Select } from "antd";
import React, { Component, ReactNode } from 'react';
import { isSafari } from "react-device-detect";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import arrow from "../../../assets/images/chevron-down.svg";
import Loading from "../../../components/Loading/Loading";
import CampaignDetailModal from "../../../components/Modals/CampaignDetailModal/CampaignDetailModal";
import CampaignFilterModal from "../../../components/Modals/CampaignFilterModal/CampaignFilterModal";
import SafariTrackerWarningModal from "../../../components/Modals/SafariTrackerWarningModal/SafariTrackerWarningModal";
import { CampaignFilterDto, CampaignFilterItemDto } from "../../../core/models/custom/campaignFilter.dto";
import { CampaignDto } from "../../../core/models/dtos/campaign.dto";
import { MeDto } from "../../../core/models/dtos/me.dto";
import { setCampaignFilter } from "../../../core/services/appService/setCampaignFilter/actions";
import { campaignFilterInitial } from "../../../core/services/appService/setCampaignFilter/reducer";
import { SetCampaignFilterState } from "../../../core/services/appService/setCampaignFilter/types";
import { getCampaigns } from "../../../core/services/campaignService/getCampaigns/actions";
import { GetCampaignsState } from "../../../core/services/campaignService/getCampaigns/types";
import { redirectCampaign } from "../../../core/services/campaignService/redirectCampaign/actions";
import { RedirectCampaignState } from "../../../core/services/campaignService/redirectCampaign/types";
import FirebaseService from "../../../core/services/firebaseService/firebase.service";
import { Helpers } from "../../../core/utilities/helpers";
import { history } from "../../../core/utilities/history";
import { IStore } from "../../../core/utilities/reducers";
import { router } from "../../../core/utilities/router";
import Calculator from "./Calculator/Calculator";
import CampaignCard from "./CampaignCard/CampaignCard";
import CampaignFilter from "./CampaignFilter/CampaignFilter";
import "./CampaignsPage.scss";
import SearchBar from "./SearchBar/SearchBar";

const { Option } = Select;

interface IProps {
  match: any;
  me?: MeDto;
  getCampaignsState: GetCampaignsState;
  getCampaigns: () => void;
  setCampaignFilterState: SetCampaignFilterState;
  setCampaignFilter: (filter: CampaignFilterDto) => void;
  redirectCampaignState: RedirectCampaignState;
  redirectCampaign: (campaignId: number) => void;
}

interface IState {
  filterHeight?: number;
  sorting: SortingValue;
  isCampaignsVisible: boolean; // to calculate campaign-filter height first
  isFilterModalVisible: boolean;
  safariTrackerWarningForCampaignId?: number;
  campaign?: CampaignDto;
}

enum SortingValue {
  cashbackDescending = 'Kazanç oranına göre azalan',
  cashbackAscending = 'Kazanç oranına göre artan',
  timeAscending = 'Tarihe göre artan',
  timeDescending = 'Tarihe göre azalan',
}

class CampaignsPage extends Component<IProps> {
  state: IState = {
    filterHeight: undefined,
    sorting: SortingValue.cashbackDescending,
    isCampaignsVisible: false,
    isFilterModalVisible: false,
    safariTrackerWarningForCampaignId: undefined,
    campaign: undefined,
  }

  private readonly campaignId: number | null;

  constructor(props: IProps) {
    super(props);
    const campaignId = this.props.match.params.campaignId;
    if (campaignId && isNaN(campaignId)) {
      history.push(router.NOT_FOUND);
    }
    this.campaignId = +campaignId;
  }

  async componentDidMount() {
    FirebaseService.logEvent(FirebaseService.all_campaigns_view);
    const campaigns = this.props.getCampaignsState.data;
    if (campaigns) {
      // Wait for arbitrary ms, page init with lower scroll position
      // if getCampaignsState.data already fetched.
      // (e.g. redirect here from home page)
      await Helpers.wait(25);
      this.initCampaigns(campaigns)
    } else {
      this.props.getCampaigns();
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (Helpers.isFreshData(prevProps.getCampaignsState, this.props.getCampaignsState)) {
      this.initCampaigns(this.props.getCampaignsState.data!);
    }
  }

  private initCampaigns(campaigns: CampaignDto[]): void {
    const campaign = campaigns.find(c => c.id === this.campaignId);
    if (this.campaignId && !campaign) {
      history.push(router.NOT_FOUND);
      return;
    }

    const brands: CampaignFilterItemDto[] = [];
    campaigns.forEach(campaign => {
      brands.push({
        ...campaign.brand,
        checked: this.props.setCampaignFilterState.data.brands.find(i => i.name === campaign.brand.name)?.checked ?? false
      });
    });

    const categories: CampaignFilterItemDto[] = [];
    Array.from(new Set(campaigns.flatMap(c => c.tags))).forEach(tag => {
      categories.push({
        name: tag,
        checked: this.props.setCampaignFilterState.data.categories.find(i => i.name === tag)?.checked ?? false
      });
    });

    this.props.setCampaignFilter({
      ...this.props.setCampaignFilterState.data,
      brands: brands.sort((a, b) => a.name > b.name ? 1 : -1),
      categories: categories.sort((a, b) => a.name > b.name ? 1 : -1),
    });

    // const minHeight = 700;
    // const height = 400 + (brands.length + campaigns.length) * 40;
    // const filterHeight = height > minHeight ? height : minHeight;
    const filterHeight = 800;
    this.setState({ filterHeight }, () => {
      this.setState({ isCampaignsVisible: true, campaign });
    });
  }

  private handleClickGoToSite(campaignId: number): void {
    if (!!this.props.me) {
      FirebaseService.logEvent(FirebaseService.go_to_site_clicked_total_with_login);
      FirebaseService.logEvent(`campaign_${campaignId}_go_to_site_clicked_total_with_login`);
      if (isSafari) {
        this.setState({ safariTrackerWarningForCampaignId: campaignId });
      } else {
        this.props.redirectCampaign(campaignId);
      }
    } else {
      FirebaseService.logEvent(FirebaseService.go_to_site_clicked_total);
      FirebaseService.logEvent(`campaign_${campaignId}_go_to_site_clicked_total`);
      history.push(router.SIGNUP);
    }
  }

  private handleModalSafariTrackerWarning(campaignId?: number): void {
    this.setState({ safariTrackerWarningForCampaignId: undefined });
    if (campaignId) {
      this.props.redirectCampaign(campaignId);
    }
  }

  private getCampaignsToRender(): CampaignDto[] {
    const campaigns = this.props.getCampaignsState.data;
    const filter = this.props.setCampaignFilterState.data;
    const selectedBrands = filter.brands.filter(i => i.checked);
    const selectedCategories = filter.categories.filter(i => i.checked);
    return campaigns?.filter(campaign => {
      return (
        (selectedBrands.length === 0 || selectedBrands.map(i => i.name).includes(campaign.brand.name)) &&
        (selectedCategories.length === 0 || selectedCategories.map(i => i.name).some(i => campaign.tags.includes(i))) &&
        (!filter.ratios.min || campaign.discount >= filter.ratios.min) &&
        (!filter.ratios.max || campaign.discount <= filter.ratios.max) &&
        (
          campaign.title.toLocaleLowerCase('tr').includes(filter.query.toLocaleLowerCase('tr')) ||
          campaign.description.toLocaleLowerCase('tr').includes(filter.query.toLocaleLowerCase('tr'))
        )
      );
    }).sort((a, b) => {
      switch (this.state.sorting) {
        case SortingValue.cashbackAscending:
          return a.discount > b.discount ? 1 : -1;
        case SortingValue.cashbackDescending:
          return a.discount > b.discount ? -1 : 1;
        case SortingValue.timeAscending:
          return a.end > b.end ? 1 : -1;
        case SortingValue.timeDescending:
          return a.end > b.end ? -1 : 1;
        default:
          return -1;
      }
    }) ?? [];
  }

  private handleClearFilter(): void {
    const data = this.props.setCampaignFilterState.data;
    this.props.setCampaignFilter({
      ...campaignFilterInitial,
      brands: data.brands.map(item => {
        item.checked = false;
        return item;
      }),
      categories: data.categories.map(item => {
        item.checked = false;
        return item;
      }),
    });
  }

  private handleChangeSorting(sorting: SortingValue): void {
    switch (sorting) {
      case SortingValue.cashbackDescending:
        FirebaseService.logEvent(FirebaseService.filter_by_highest_cashback_rate);
        break;
      case SortingValue.cashbackAscending:
        FirebaseService.logEvent(FirebaseService.filter_by_lowest_cashback_rate);
        break;
      case SortingValue.timeAscending:
        FirebaseService.logEvent(FirebaseService.filter_by_highest_time_left);
        break;
      case SortingValue.timeDescending:
        FirebaseService.logEvent(FirebaseService.filter_by_lowest_tiime_left);
        break;
    }
    this.setState({ sorting });
  }

  private renderSorting(): ReactNode {
    return (
      <div className="sorting-wrapper">
        <span className="label">Sırala:</span>
        <Select
          className="sorting"
          dropdownClassName="sorting-dropdown"
          suffixIcon={<img src={arrow} alt="arrow" />}
          value={this.state.sorting}
          onChange={sorting => this.handleChangeSorting(sorting)}
        >
          <Option key={`sorting${SortingValue.cashbackDescending}`} value={SortingValue.cashbackDescending}>
            {SortingValue.cashbackDescending}
          </Option>
          <Option key={`sorting${SortingValue.cashbackAscending}`} value={SortingValue.cashbackAscending}>
            {SortingValue.cashbackAscending}
          </Option>
          <Option key={`sorting${SortingValue.timeAscending}`} value={SortingValue.timeAscending}>
            {SortingValue.timeAscending}
          </Option>
          <Option key={`sorting${SortingValue.timeDescending}`} value={SortingValue.timeDescending}>
            {SortingValue.timeDescending}
          </Option>
        </Select>
      </div>
    );
  }

  private renderCampaigns(): ReactNode {
    const campaigns = this.getCampaignsToRender().map(campaign => {
      return (
        <CampaignCard
          key={`campaign-${campaign.id}`}
          me={this.props.me}
          campaign={campaign}
          isRedirectLoading={this.props.redirectCampaignState.loading}
          callbackDetailPopup={campaign => this.setState({ campaign })}
          callbackRedirectSite={campaign => this.handleClickGoToSite(campaign.id)}
        />
      );
    });
    return (
      <div className="campaigns-wrapper">
        {
          campaigns.length > 0 &&
          this.renderSorting()
        }
        {
          this.state.isCampaignsVisible &&
          <React.Fragment>
            <div className="campaigns d-flex d-lg-none">
              {campaigns}
            </div>
            <div
              className="campaigns d-none d-lg-flex"
              style={{ height: `${this.state.filterHeight! - 60}px` }}
            >
              {campaigns}
            </div>
          </React.Fragment>
        }
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <SearchBar
          callbackClickFilter={() => this.setState({ isFilterModalVisible: true })}
        />
        <div id="campaigns-page" className="page">
          <div className="page-content">
            {
              this.props.getCampaignsState.loading
                ?
                <Loading className="m-auto" fontSize={48} />
                :
                <React.Fragment>
                  {
                    this.state.isCampaignsVisible &&
                    <CampaignFilter
                      callbackClearFilter={() => this.handleClearFilter()}
                    />
                  }
                  {this.renderCampaigns()}
                </React.Fragment>
            }
          </div>
        </div>
        {
          this.state.campaign &&
          <CampaignDetailModal
            campaign={this.state.campaign}
            isRedirectLoading={this.props.redirectCampaignState.loading}
            callbackRedirectSite={campaign => this.handleClickGoToSite(campaign.id)}
            callbackClose={() => this.setState({ campaign: undefined })}
          />
        }
        {
          this.state.isFilterModalVisible &&
          <CampaignFilterModal
            callbackClearFilter={() => this.handleClearFilter()}
            callbackClose={() => this.setState({ isFilterModalVisible: false })}
          />
        }
        {
          this.state.safariTrackerWarningForCampaignId &&
          <SafariTrackerWarningModal
            campaignId={this.state.safariTrackerWarningForCampaignId}
            callback={(campaignId) => this.handleModalSafariTrackerWarning(campaignId)}
          />
        }
        <Calculator />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getCampaigns,
      setCampaignFilter,
      redirectCampaign,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    me: store.setMe.me,
    getCampaignsState: store.getCampaigns,
    setCampaignFilterState: store.setCampaignFilter,
    redirectCampaignState: store.redirectCampaign,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsPage);
