import {Button, Form, Input} from "antd";
import React, {useEffect, useState} from 'react';
import suffix from "../../../../assets/images/otp-suffix.svg";
import {FormError} from "../../../../core/models/custom/formError";
import {FormValuesOtp} from "../../../../core/models/custom/formValuesOtp";
import FirebaseService from "../../../../core/services/firebaseService/firebase.service";
import {Constants} from "../../../../core/utilities/constants";
import "./OtpForm.scss";

interface IProps {
  isSignup?: boolean;
  isLoading: boolean;
  callbackSubmit: (values: FormValuesOtp) => void;
}

const OtpForm = (props: IProps) => {
  const [isSubmitted, setSubmitted] = useState(false);
  const [isOtpValid, setOtpValid] = useState(false);
  const [isOtpError, setOtpError] = useState(false);
  const [form] = Form.useForm();
  
  useEffect(() => {
    if (props.isSignup) {
      FirebaseService.logEvent(FirebaseService.sign_up_otp_view);
    } else {
      FirebaseService.logEvent(FirebaseService.login_otp_view);
    }
  }, [props.isSignup])
  
  function handleOtpChange(otp: string): void {
    if (otp) {
      setOtpValid(otp.length === Constants.otpLength);
      if (otp.length > Constants.otpLength) {
        form.setFieldsValue({otp: otp.substring(0, Constants.otpLength)});
        setOtpValid(true);
        setOtpError(false);
      } else if (isSubmitted) {
        setOtpError(otp.length !== Constants.otpLength);
      }
    }
  }
  
  function handleOtpKeyPress(e: React.KeyboardEvent): void {
    if (isNaN(parseInt(e.key))) {
      e.preventDefault();
    }
  }
  
  function handleFail(e: any): void {
    setSubmitted(true);
    setOtpError((e as FormError).errorFields.filter(err => err.name.includes('otp')).length > 0);
  }
  
  function handleFinish(values: FormValuesOtp): void {
    if (props.isSignup) {
      FirebaseService.logEvent(FirebaseService.sign_up_otp_continue_clicked);
    } else {
      FirebaseService.logEvent(FirebaseService.login_otp_continue_clicked);
    }
    props.callbackSubmit(values);
  }
  
  return (
    <Form
      form={form}
      id="otp-form"
      onFinishFailed={handleFail}
      onFinish={handleFinish}
    >
      <div className={`app-input ${isOtpValid && 'valid'} ${isOtpError && 'error'}`}>
        <div className="input-wrapper">
          <span className="label">Doğrulama kodun</span>
          <Form.Item
            name="otp"
            className="input"
            rules={[
              {required: true, message: ''},
              {len: Constants.otpLength, message: ''},
            ]}
          >
            <Input
              autoFocus
              type="tel"
              onChange={e => handleOtpChange(e.target.value)}
              onKeyPress={handleOtpKeyPress}
            />
          </Form.Item>
        </div>
        <span className="suffix pb-1">
          <img src={suffix} alt="key"/>
        </span>
      </div>
      
      <span className="info">
        {isOtpError
          ? 'Lütfen telefonuna SMS ile gelen kodu kontrol ederek tekrar gir.'
          : 'Lütfen SMS ile gelen doğrulama kodunu gir.'
        }
      </span>
      
      <Button
        htmlType="submit"
        className="app-button"
        disabled={props.isLoading}
      >
        {props.isSignup ? 'Devam Et' : 'Doğrula'}
      </Button>
    </Form>
  );
}

export default OtpForm;
