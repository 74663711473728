import {Button} from "antd";
import React, {Component, ReactNode} from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {bindActionCreators, Dispatch} from "redux";
import {FormValuesOtp} from "../../../core/models/custom/formValuesOtp";
import {FormValuesSignUp} from "../../../core/models/custom/formValuesSignUp";
import {OtpRequest} from "../../../core/models/requests/otp.request";
import {SignUpRequest} from "../../../core/models/requests/signUp.request";
import {signUp, signUpReset} from "../../../core/services/authService/signUp/actions";
import {SignUpState} from "../../../core/services/authService/signUp/types";
import {signUpOtp, signUpOtpReset} from "../../../core/services/authService/signUpOtp/actions";
import {SignUpOtpState} from "../../../core/services/authService/signUpOtp/types";
import FirebaseService from "../../../core/services/firebaseService/firebase.service";
import {getMe} from "../../../core/services/userService/getMe/actions";
import {GetMeState} from "../../../core/services/userService/getMe/types";
import {LocalStorage} from "../../../core/utilities/localStorage";
import {IStore} from "../../../core/utilities/reducers";
import {router} from "../../../core/utilities/router";
import OtpForm from "../Common/OtpForm/OtpForm";
import SignUpBreadcrumb, {SignUpStep} from "./SignUpBreadcrumb/SignUpBreadcrumb";
import SignUpForm from "./SignUpForm/SignUpForm";
import "./SignUpPage.scss";

interface IProps {
  signUpOtpState: SignUpOtpState;
  signUpOtp: (request: OtpRequest) => void;
  signUpOtpReset: () => void;
  signUpState: SignUpState;
  signUp: (request: SignUpRequest) => void;
  signUpReset: () => void;
  getMeState: GetMeState;
  getMe: () => void;
}

interface IState {
  etkApproved: boolean;
}

class SignUpPage extends Component<IProps> {
  state: IState = {
    etkApproved: false,
  }
  
  componentDidMount() {
    FirebaseService.logEvent(FirebaseService.sign_up_view);
    this.props.signUpOtpReset();
  }
  
  private getMsisdnInitial(): string {
    const msisdn = LocalStorage.get(LocalStorage.msisdn);
    if (msisdn) {
      LocalStorage.remove(LocalStorage.msisdn);
      return msisdn.slice(2);
    }
    return "";
  }
  
  private getStep(): SignUpStep {
    if (this.props.signUpState.data) {
      return SignUpStep.complete;
    } else if (this.props.signUpOtpState.data) {
      return SignUpStep.otp
    }
    return SignUpStep.signup;
  }
  
  private handleSignupForm(values: FormValuesSignUp): void {
    this.setState({etkApproved: values.etkApproved ?? false});
    this.props.signUpOtp({msisdn: values.msisdn});
  }
  
  private handleOtpForm(values: FormValuesOtp): void {
    if (!this.props.signUpOtpState.data) {
      alert('Msisdn not defined!');
      return;
    }
    this.props.signUp({
      otp: values.otp,
      msisdn: this.props.signUpOtpState.data,
      etkApproved: this.state.etkApproved,
    });
  }
  
  private renderStepSignup(): ReactNode {
    return (
      <React.Fragment>
        <p className="form-note">
          Hemen bir hesap oluşturarak kazanmaya başla!
        </p>
        <SignUpForm
          msisdnInitial={this.getMsisdnInitial()}
          isLoading={this.props.signUpOtpState.loading}
          callbackSubmit={values => this.handleSignupForm(values)}
        />
        <NavLink
          to={router.LOGIN} className="link-login"
          onClick={() => FirebaseService.logEvent(FirebaseService.sign_up_login_clicked)}
        >
          <span className="already-have-acc">Zaten bir hesabın var mı? Giriş Yap</span>
        </NavLink>
      </React.Fragment>
    );
  }
  
  private renderStepOtp(): ReactNode {
    return (
      <OtpForm
        isSignup={true}
        isLoading={this.props.signUpState.loading}
        callbackSubmit={values => this.handleOtpForm(values)}
      />
    );
  }
  
  private renderStepComplete(): ReactNode {
    return (
      <div className="complete-content">
        <div className="image d-flex d-md-none"/>
        <span className="title">
          Aramıza hoş geldin!
        </span>
        <span className="text">
          Artık kazanmaya başlayabilirsin.
        </span>
        <Button
          className="app-button"
          disabled={this.props.getMeState.loading}
          onClick={() => {
            FirebaseService.logEvent(FirebaseService.sign_up_success_continue_clicked);
            LocalStorage.set(LocalStorage.redirect, router.HOW_IT_WORKS);
            this.props.getMe();
          }}
        >
          Alışverişe Başla
        </Button>
      </div>
    );
  }
  
  private renderStepContent(): ReactNode {
    const step = this.getStep();
    switch (step) {
      case SignUpStep.signup:
        return this.renderStepSignup();
      case SignUpStep.otp:
        return this.renderStepOtp();
      case SignUpStep.complete:
        return this.renderStepComplete();
      default:
        return <React.Fragment/>
    }
  }
  
  render() {
    const step = this.getStep();
    return (
      <div id="sign-up-page" className="page">
        <div className="page-content">
          <div className="step-content">
            {
              step !== SignUpStep.complete &&
              <SignUpBreadcrumb step={step}/>
            }
            {this.renderStepContent()}
          </div>
          <div className={`image-wrapper ${step === SignUpStep.signup && 'center'}`}>
            <div className={`image ${this.props.signUpState.data && 'success'}`}/>
          </div>
        </div>
      </div>
    );
  }
  
  componentWillUnmount() {
    this.props.signUpOtpReset();
    this.props.signUpReset();
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      signUpOtp,
      signUpOtpReset,
      signUp,
      signUpReset,
      getMe,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    signUpOtpState: store.signUpOtp,
    signUpState: store.signUp,
    getMeState: store.getMe,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
