import {Dispatch} from 'redux';
import {LocalStorage} from "../../../utilities/localStorage";
import FirebaseService from "../../firebaseService/firebase.service";
import {SET_UNREAD_NOTIFICATION_IDS} from './constants';
import {SetUnreadNotificationIdsAction} from './types';

const setUnreadNotificationIdsAction = (payload: number[]): SetUnreadNotificationIdsAction => {
  return {
    type: SET_UNREAD_NOTIFICATION_IDS,
    payload: payload,
  };
};

export const setUnreadNotificationIds = (data?: number[]) => async (
  dispatch: Dispatch,
) => {
  let ids = data;
  if (!data) {
    const seenNotificationIds = JSON.parse(LocalStorage.get(LocalStorage.notificationIds) ?? '[]') as number[];
    const notificationIds = FirebaseService.getNotifications().map(n => n.id);
    ids = notificationIds.filter(x => !seenNotificationIds.includes(x));
  }
  dispatch(setUnreadNotificationIdsAction(ids ?? []));
};
