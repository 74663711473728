import {CampaignFilterDto} from "../../../models/custom/campaignFilter.dto";
import {SET_CAMPAIGN_FILTER} from "./constants";
import {SetCampaignFilterActions, SetCampaignFilterState} from "./types";

export const campaignFilterInitial: CampaignFilterDto = {
  query: '',
  brands: [],
  categories: [],
  ratios: {
    min: undefined,
    max: undefined,
  }
}

const initialState: SetCampaignFilterState = {
  data: campaignFilterInitial,
};

const reducer = (
  state = initialState,
  action: SetCampaignFilterActions,
): SetCampaignFilterState => {
  switch (action.type) {
    case SET_CAMPAIGN_FILTER:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
