import {Button} from "antd";
import React, {Component} from "react";
import FirebaseKey from "../../../components/FirebaseKey/FirebaseKey";
import FirebaseService from "../../../core/services/firebaseService/firebase.service";
import {history} from "../../../core/utilities/history";
import {router} from "../../../core/utilities/router";
import "./NotFoundPage.scss";

interface IProps {
}

class NotFoundPage extends Component<IProps> {

  render() {
    return (
      <div id="not-found-page" className="page">
        <div className="page-content">
          <div className="step-content">
            <div className="error-content">
              <div className="image d-flex d-md-none"/>
              <span className="title">
                <FirebaseKey firebaseKey={FirebaseService.error_page_title}/>
              </span>
              <span className="text">
                <FirebaseKey firebaseKey={FirebaseService.error_page_desc}/>
              </span>
              <Button
                className="app-button outline"
                onClick={() => history.push(router.LANDING)}
              >
                <FirebaseKey firebaseKey={FirebaseService.error_page_button}/>
              </Button>
            </div>
          </div>
          <div className="image-wrapper">
            <div className="image"/>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundPage;
