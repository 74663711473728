import { Modal } from 'antd';
import { ReactNode } from 'react';
import calculatorGreen from "../../../assets/images/calculator-green.svg";
import close from "../../../assets/images/close-green.svg";
import FirebaseService from '../../../core/services/firebaseService/firebase.service';
import FirebaseKey from '../../FirebaseKey/FirebaseKey';
import './CalculatorModal.scss';
import CalculatorModalForm from './CalculatorModalForm/CalculatorModalForm';

interface IProps {
  isVisible: boolean;
  callbackClose: () => void;
}

const CalculatorModal = (props: IProps) => {

  function renderModalTitle(): ReactNode {
    return (
      <div className="d-flex">
        <img className='mr-2' src={calculatorGreen} alt="calculator" />
        <FirebaseKey
          firebaseKey={FirebaseService.calculator_popup_title}
        />
      </div>
    )
  }

  return (
    <Modal
      wrapClassName="calculator-modal-wrapper"
      className="calculator-modal"
      visible={props.isVisible}
      getContainer={false}
      footer={false}
      mask={false}
      maskClosable={false}
      destroyOnClose
      onCancel={props.callbackClose}
      closeIcon={<img src={close} alt="close" />}
      title={renderModalTitle()}
      width={200}
    >
      <CalculatorModalForm />
    </Modal>
  );
};

export default CalculatorModal;
