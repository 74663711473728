import React from 'react';
import "./PageTitle.scss"

interface IProps {
    className?: string;
    text: string;
}

const PageTitle = (props: IProps) => {
    return (
      <div id="page-title">
        <div className="page-title-content">
        <h1
          className={props.className}
        >
          {props.text}
        </h1>
        </div>
        </div>
    )
}

export default PageTitle;