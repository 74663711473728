import {REDIRECT_CAMPAIGN_START, REDIRECT_CAMPAIGN_SUCCESS, REDIRECT_CAMPAIGN_FAIL, REDIRECT_CAMPAIGN_RESET} from './constants';
import {RedirectCampaignActions, RedirectCampaignState} from './types';

const initialState: RedirectCampaignState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: RedirectCampaignActions,
): RedirectCampaignState => {
  switch (action.type) {
    case REDIRECT_CAMPAIGN_START:
      return {
        ...initialState,
        loading: true,
      };
    case REDIRECT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case REDIRECT_CAMPAIGN_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case REDIRECT_CAMPAIGN_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
